import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import useNavigate from '../../hooks/useNavigate';
import { indigo } from '@mui/material/colors';
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router';

const drawerWidth = 240;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: '#363A3C',
  color: 'white',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  background: '#363A3C',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const NDrawer = ({ drawerOpen, adminTabs, jsonTabs, orgmanTabs, handleDrawerClose, globalActions }) => {
  const navigate = useNavigate();
  const location = useLocation();

  function navigationHandler(route) {
    return () => {
      // Check if it's in the same base path
      // TODO: utilize react router
      if (!window.location.pathname.includes(route)) {
        navigate(`/${route}`);
      }

      if (window.location.pathname.includes(route)) {
        // trigger loadJsonContent in Router
        globalActions.reloadJsonContent();
      }

      handleDrawerClose();
    };
  }

  return (
    <Drawer variant="permanent" open={drawerOpen}>
      <DrawerHeader sx={{ minHeight: '56px !important' }}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon fontSize="large" sx={{ color: 'white' }} />
        </IconButton>
      </DrawerHeader>
      <Divider />

      <List sx={!drawerOpen && { marginTop: '60px' }}>
        { !window.location.pathname.includes('orgman') && jsonTabs.map(tab => {
          let match = window.location.pathname.includes(tab.route);
          let bgcolor = '#63A7AA';
          if (tab.route === 'user/calendar') {
            bgcolor = '#BD6B58';
          }
          return (
            <ListItem
              key={tab.label}
              disablePadding
              sx={{
                display: 'block',
                background: match ? bgcolor : '',
              }}
            >
              <MenuTooltip label={tab.label}>
                <ListItemButton
                  onClick={navigationHandler(tab.route)}
                  sx={{
                    color: 'white',
                    minHeight: 48,
                    justifyContent: drawerOpen ? 'initial' : 'center',
                    width: '100%',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      mr: drawerOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                    color="secondary"
                  >
                    <FontAwesomeIcon size="lg" icon={tab.icon || ''} color="white" />
                  </ListItemIcon>
                  <ListItemText
                    primary={tab.label}
                    sx={{
                      opacity: drawerOpen ? 1 : 0,
                      color: 'white',
                    }}
                  />
                </ListItemButton>
              </MenuTooltip>
            </ListItem>
          );
        })}
        { !window.location.pathname.includes('orgman') && ( <Divider sx={{ mt: 2 }} /> ) }
        { !window.location.pathname.includes('orgman') && adminTabs.map(tab => {
          let match = window.location.pathname.includes(tab.route);
          return (
            <ListItem key={'admin_' + tab.label} disablePadding sx={{ display: 'block', color: 'gray' }}>
              <MenuTooltip label={tab.label}>
                <ListItemButton
                  onClick={navigationHandler(tab.route)}
                  sx={{
                    minHeight: 48,
                    justifyContent: drawerOpen ? 'initial' : 'center',
                    width: '100%',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      mr: drawerOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {tab.icon && tab.icon !== 'duties' && (
                      <FontAwesomeIcon icon={tab.icon || 'user-cog'} size="lg" color={match ? indigo[500] : 'white'} />
                    )}
                    {tab.icon && tab.icon === 'duties' && (
                      <FontAwesomeIcon icon={'user-cog'} size="lg" color={match ? indigo[500] : 'white'} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={tab.label}
                    sx={{
                      opacity: drawerOpen ? 1 : 0,
                      color: match ? indigo[500] : 'white',
                    }}
                  />
                </ListItemButton>
              </MenuTooltip>
            </ListItem>
          );
        })}
        {window.location.pathname.includes('orgman') && orgmanTabs.map(tab => {
          let match = window.location.pathname.includes(tab.route);
          return (
            <ListItem key={'orgman_' + tab.label} disablePadding sx={{ display: 'block', color: 'gray' }}>
              <MenuTooltip label={tab.label}>
                <ListItemButton
                  onClick={navigationHandler(tab.route)}
                  sx={{
                    minHeight: 48,
                    justifyContent: drawerOpen ? 'initial' : 'center',
                    width: '100%',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      mr: drawerOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
		  <FontAwesomeIcon icon={tab.icon || 'user-cog'} size="lg" color={match ? indigo[500] : 'white'} />
                  </ListItemIcon>
                  <ListItemText
                    primary={tab.label}
                    sx={{
                      opacity: drawerOpen ? 1 : 0,
                      color: match ? indigo[500] : 'white',
                    }}
                  />
                </ListItemButton>
              </MenuTooltip>
            </ListItem>
          );
        })} 
      </List>
    </Drawer>
  );
};

function MenuTooltip(props) {
  return (
    <Tooltip title={<h6>{props.label}</h6>} placement="right" arrow>
      {props.children}
    </Tooltip>
  );
}

export default NDrawer;
