import React from 'react';
import { getContentFromEndpoint, getAllUrlParams } from '../../actions/API';
import Loading from 'react-loading';

//potential ROUTES
import OEditor from '../Common/OEditor/ObjectEditor';
//import ObjectEditor from '../Common/ObjectEditor/ObjectEditor';
import Form from '../Common/Form';
import UserStats from '../Admin/UserEditor/UserStats';
import UserSchedule from '../Admin/UserEditor/UserSchedule';
import CommandPanel from '../Common/CommandPanel/CommandPanel';
import Reports2 from '../Common/Reports2';
import Calendar from '../Calendar/Calendar';
import Dashboard from '../Dashboard/Dashboard';
import DayPage from '../../containers/DayPage';
import ReportsPage from '../../containers/ReportsPage';
import Settings from '../Settings/Settings';
import Manager from '../Manager';
import APIMap from '../Admin/APIMap/APIMap';
import Records from '../Records/Records';
import OperationsBoard from '../OperationsBoard/OperationsBoard';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import * as actions from '../../actions/GlobalActions';
import { bindActionCreators } from 'redux';

const endpointTypes = require('../../constants/endpointTypes.js');

class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonContent: props.preLoadedData,
      loading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    
    if (this.props.noLoadingData) {
      return;
    }

    if (this.props.preLoadedData) {
      // DEV-918 loadJsonContent without show loading bar
      this.loadJsonContent();
      this.setState({
        jsonContent: this.props.preLoadedData,
        loading: false,
      });
    } else {
      this.loadJsonContent();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { noLoadingData, preLoadedData } = this.props;
    if (
      this.props !== prevProps &&
      noLoadingData &&
      ((!prevProps.preLoadedData && preLoadedData) || prevProps.preLoadedData !== this.props.preLoadedData)
    ) {
      this.setState({
        jsonContent: preLoadedData,
        loading: false,
      });
    }

    // trigger loadJsonContent on pending (props.view.reloadJsonContent = true)
    if (!prevProps?.view?.reloadJsonContent && this.props?.view?.reloadJsonContent) {
      this.loadJsonContent();
    }

    // set reloadJsonContent to false on fulfilled (state.loading = false)
    if (prevState.loading && !this.state.loading && this.props?.globalActions) {
      this.props?.globalActions?.fulfillJsonContent();
    }
  }

  loadJsonContent(moreParams = null, seldate = null) {
    this.setState({ loading: true });
    if (!this.props.content || !this.props.content.endpoint) {
      return this.setState({
        loading: false,
      });
    }
    let params = this.props.params ? this.props.params : {};

    if (moreParams) {
      params = Object.assign({}, params, moreParams);
    }
    if (this.props.content.variables && this.props.content.endpoint === 'POST') {
      params = Object.assign({}, params, this.props.content.variables);
    }
    if (seldate) {
      params = Object.assign({}, params, { seldate: seldate });
    }
    params = Object.assign({}, getAllUrlParams(this.props.content.endpoint), params);
    getContentFromEndpoint(params, this.props.content.endpoint.split('?')[0], this.props.content.method)
      .then(
        response => {
          this._isMounted &&
            this.setState({
              jsonContent: response,
              loading: false,
            });
          // console.log("If there is a banner in the response, it can be updated now.");
          if (response.data && response.data.banner) {
            this._isMounted && this.props.updateBanner(response.data.banner, true);
          }
          if (response.data && response.data.ribbon) {
            this._isMounted && this.props.updateRibbon(response.data.ribbon, true);
          }
        },
        () => {
          // if we got an error, set loading to false but do not set jsonContent, so
          // that an error message will be displayed
          this.state.loading && this.setState({ loading: false });
        },
      )
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    //if we are still loading the data, display loading bars instead of trying to
    // display data
    if ((this.state.loading && !this.state.jsonContent) || (this.state.loading && this.props.view.reloadJsonContent)) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 250px)',
            py: '30px',
          }}>
          <Loading className="mx-auto" type="bars" color="#e3e3e3" />
        </Box>
      );
    }

    const { endpoint } = this.props.content;

    //these endpoints imported from the React Router system are staying outside
    // the check for the endpoint data for now as they do not currently rely on this data
    // going forward we will move them one by one inside of the check as we
    // overhaul each component to work dynamically

    switch (this.props.content.endpoint_type) {
      case endpointTypes.REPORTS:
        return <ReportsPage />;
      case endpointTypes.REPORTS2:
        return (
          <Reports2
            key={'reports2' + new Date().getTime()}
            data={this.state.jsonContent}
            refreshData={this.loadJsonContent.bind(this)}
          />
        );
      case endpointTypes.SETTINGS:
        return <Settings />;
      case endpointTypes.MANAGER:
        return <Manager />;
      case endpointTypes.DAY:
        return <DayPage />;
      default:
        break;
    }

    //TODO: move ALL parsing into individual endpoints, just pass jsonContent through
    if (this.state.jsonContent) {
      switch (this.props.content.endpoint_type) {
        case endpointTypes.OPERATIONS_BOARD:
          return (
            <OperationsBoard
              updateRibbon={this.props.updateRibbon}
              data={this.state.jsonContent}
              refreshObjects={(seldate = null) => this.loadJsonContent(null, seldate)}
            />
          );
        case endpointTypes.CALENDAR:
          return (
            <Calendar
              updateRibbon={this.props.updateRibbon}
              data={this.state.jsonContent}
              endpoint_info={this.props.content}
              globalActions={this.props.globalActions}
              tabs={this.props.tabs}
            />
          );
        case endpointTypes.DAYVIEW:
          return (
            <Calendar
              showDayView={true}
              data={this.state.jsonContent}
              updateRibbon={this.props.updateRibbon}
              dayViewEndpoint={this.props.content.endpoint}
              dayViewParams={this.props.params}
              globalActions={this.props.globalActions}
              tabs={this.props.tabs}
            />
          );
        case endpointTypes.DASHBOARD:
          return (
            <Dashboard
              data={this.state.jsonContent}
              tabs={this.props.tabs}
              globalActions={this.props.globalActions}
              refreshObjects={this.loadJsonContent.bind(this)}
            />
          );
        case endpointTypes.SIMPLE_EDITOR:
          return (
            <OEditor
              objectData={this.state.jsonContent.data}
              key="oeSkills"
              simple="true"
              refreshObjects={this.loadJsonContent.bind(this)}
            />
          );
        case endpointTypes.FULL_EDITOR:
          return (
            <OEditor
              key="oeUsers"
              objectData={this.state.jsonContent.data}
              refreshObjects={this.loadJsonContent.bind(this)}
            />
          );
        case endpointTypes.FORM:
          return (
            <Form
              formData={this.state.jsonContent.data.data}
              openDialog={this.props.openDialog}
              standAlone={typeof this.props.standAlone !== 'undefined' ? this.props.standAlone : true}
              success={this.props.success}
              onClose={this.loadJsonContent.bind(this)}
            />
          );
        case endpointTypes.USER_STATS:
          return <UserStats stats={this.state.jsonContent.data.data} />;
        case endpointTypes.USER_SCHEDULE:
          return <UserSchedule scheduleData={this.state.jsonContent.data} />;
        case endpointTypes.COMMAND_PANEL:
          return <CommandPanel data={this.state.jsonContent} refreshData={this.loadJsonContent.bind(this)} />;
        case endpointTypes.HTML:
          return (
            <div
              className="htmlEndpointContainer"
              dangerouslySetInnerHTML={{
                __html: this.state.jsonContent.data.data,
              }}
            />
          );
        case endpointTypes.RAW_HTML:
          return (
            <div
              className="htmlEndpointContainer"
              dangerouslySetInnerHTML={{
                __html: this.state.jsonContent,
              }}
            />
          );
        case endpointTypes.API_MAP:
          return <APIMap data={this.state.jsonContent} refreshState={this.loadJsonContent.bind(this)} />;
        case endpointTypes.RECORDS:
          return <Records data={this.state.jsonContent} refreshState={this.loadJsonContent.bind(this)} />;
        default:
          return <div className="endpoint">Endpoint type "{this.props.content.endpoint_type}" does not exist yet</div>;
      }
    } else {
      return <div className="endpoint">Endpoint "{endpoint}" does not exist yet</div>; //TODO: clean this up. no dupe
    }
  }
}

function mapStateToProps(state) {
  return { view: state.ViewReducer };
}

function mapDispatchToProps(dispatch) {
  return {
    globalActions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
