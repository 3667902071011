import React from 'react';
import { withRouter } from 'react-router-dom';
import Loading from 'react-loading';
import moment from 'moment';
import APIResponseDialog from './APIResponseDialog';
import DialogBox from './DialogBox';
import Modal from './Modal';
import WrappedSelect from './WrappedSelect';
import Records from './Records';
import Buttonbar from './Buttonbar';
import { isMobile } from 'react-device-detect';
import { dynamicOEButtonCall } from '../../actions/API';
import { get } from '../../actions/REST';
import CustomToolTip from './CustomToolTip';
import getSizeInPixel from '../../constants/boxSizes';
import MuiCheckbox from '../MuiForm/Checkbox';
import MuiTextField from '../MuiForm/TextField';
import MuiRadioButton from '../MuiForm/Radio';
//import MuiTimePicker from '../MuiForm/TimePicker';
//import MuiDatePicker from '../MuiForm/DatePicker';
//import MuiDateTimePicker from '../MuiForm/DateTimePicker';
import WrappedDatetime from './WrappedDateTimePicker';
import WrappedTimePicker from './WrappedTimePicker';
import MuiTextArea from '../MuiForm/TextArea';
import MuiDateRange from '../MuiForm/DateRange';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

const buttonTypes = require('../../constants/buttonTypes');
const inputTypes = require('../../constants/inputTypes');
const formControlTypes = require('../../constants/formControlTypes');

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: null,
      updatedValues: null,
      loading: false,
      dialog: {
        response: null,
        open: false,
        buttonCallback: null,
        button: null,
      },
      toggleBtns: {},
      hiddenFields: [],
      dialog_box: {
        data: null,
        show: false,
        dialog_type: null,
        controller: null,
      },
      dynamicId: null,
      control_panel: null,
      corner_control_panel: null,
    };
  }

  openDialog(response, buttonCallback, button = null) {
    // just bump it open then immediately close on this end, to leave timing
    // up to the dialog component
    this.setState(
      {
        loading: false,
        dialog: {
          open: true,
          response,
          buttonCallback,
          button,
        },
      },
      () => {
        // TODO: create a propper notificatio component that stackable
        setTimeout(() => {
          this?.setState({
            dialog: { open: false, response, buttonCallback, button },
          });
        }, 3000);
      },
    );
  }

  componentDidUpdate(prevProps) {
     this.props.formData !== prevProps.formData && this.init(this.props.formData);
  }

  componentDidMount() {
    this.init(this.props.formData);
  }

  initColumns(columns) {
    let initialSubmitValues = typeof this.initialValues === 'undefined' ? {} : this.initialValues;
    let hiddenFields = [];
    let toggleBtns = {};

    for (let k = 0; k < columns.length; k++) {
      let containers = columns[k].containers;
      for (let i = 0; i < containers.length; i++) {
        let rawFields = containers[i].fields;
        for (let j = 0; j < rawFields.length; j++) {
          let fields = [];
          fields.push(rawFields[j]);
          //Go through the fields that will appear in the same row
          if (rawFields[j].row_fields && rawFields[j].row_fields.length > 0) {
            rawFields[j].row_fields.map(row_field => fields.push(row_field));
          }
          for (let field of fields) {
            //initialize the map of values to be submitted with the form
            if (field.value) {
              initialSubmitValues[field.name] = field.value;
            } else if (field.startDefault && field.endDefault) {
              //TODO: write a parser so that this won't be necessary here
              initialSubmitValues[field.name] = {
                start: field.startDefault,
                end: field.endDefault,
              };
            } else {
              initialSubmitValues[field.name] = field.range;
            }
            if (field.input_type === inputTypes.CHECKBOX) {
              initialSubmitValues[field.name] = field.selected ? field.value : false;
            }

            // For the purpose of the Remember me Functionality in Login page
            if (this.props.location.pathname === '/login' && this.props.localStorageEmail) {
              initialSubmitValues.username = this.props.localStorageEmail;
            }

            //initialize hidden fields
            // TODO: merge this with handleControllerShift when we have more controller types
            if (field.form_controllers) {
              for (let i = 0; i < field.form_controllers.length; i++) {
                let controller = field.form_controllers[i];
                let match = Array.isArray(controller.on_value)
                  ? controller.on_value.includes(field.value)
                  : controller.on_value == field.value;
                if (
                  (controller.control_type === formControlTypes.VISIBILITY_ON && !match) ||
                  (controller.control_type === formControlTypes.VISIBILITY_OFF && match)
                ) {
                  Array.prototype.push.apply(hiddenFields, controller.controlled);
                }
              }
            } else if (field.input_type === inputTypes.HTML && Array.isArray(field.value)) {
              for (let v of field.value) {
                if (Array.isArray(v.buttonbar) || Array.isArray(v.lbuttonbar)) {
                  const buttonbar = v.lbuttonbar ? v.lbuttonbar : v.buttonbar;
                  for (let button of buttonbar) {
                    if (button && button.action === 'toggle' && button.form_controllers) {
                      let state = true;
                      for (let control of button.form_controllers) {
                        if (control.control_type === formControlTypes.VISIBILITY_ON && !control.default) {
                          Array.prototype.push.apply(hiddenFields, control.controlled);
                          state = false;
                        }
                      }
                      toggleBtns = {
                        [button.name]: state,
                        ...toggleBtns,
                      };
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    this.initialValues = initialSubmitValues;
    this.setState({
      toggleBtns,
      updatedValues: initialSubmitValues,
      hiddenFields: hiddenFields,
    });
  }

  //initialize the submit values of the form from the initial values in props
  // and the state of the form based on the intial state of the form controllers
  init(formData, dynamicId = null) {
    if (formData) {
      if (formData.rows) {
        for (let k = 0; k < formData.rows.length; k++) {
          formData.rows[k].columns && this.initColumns(formData.rows[k].columns);
        }
      }
      formData.columns && this.initColumns(formData.columns);

      /*
       ** Extract the buttons id and state and format it {id: state}
       ** for example {save_button: false} would mean that this button is
       ** not disabled.
       */
      let control_panel = {};
      if (formData.control_panel && formData.control_panel.buttons) {
        for (let button of formData.control_panel.buttons) {
          control_panel = Object.assign(control_panel, {
            [button.id]: button.disabled,
          });
        }
      }

      let corner_control_panel = {};
      if (formData.corner_control_panel && formData.corner_control_panel.buttons) {
        for (let button of formData.corner_control_panel.buttons) {
          corner_control_panel = Object.assign(control_panel, {
            [button.id]: button.disabled,
          });
        }
      }

      this.setState({
        formData,
        dynamicId,
        control_panel,
        corner_control_panel,
      });
    }
  }

  handleButton(button, uniqueID) {
    if (button.action === 'toggle' && Array.isArray(button.form_controllers)) {
      let buttonControlled = [];
      //Collect form controllers
      button.form_controllers.map(control => {
        if (Array.isArray(control.controlled)) {
          control.controlled.map(field => buttonControlled.push(field));
        }
        return control;
      });

      //If we have fields that are hidden already we have to check if
      //the button being pressed controlls those fields
      //- If it does ? Remove it : If not then add them
      let remove = [];
      let add = [];
      if (Array.isArray(this.state.hiddenFields) && this.state.hiddenFields.length) {
        for (let field of buttonControlled) {
          if (this.state.hiddenFields.includes(field)) {
            remove.push(field);
          } else {
            add.push(field);
          }
        }
      } else {
        add = buttonControlled;
      }

      if (add.length) {
        this.setState(prevState => ({
          toggleBtns: {
            ...prevState.toggleBtns,
            [button.name]: false,
          },
          hiddenFields: prevState.hiddenFields ? prevState.hiddenFields.concat(add) : add,
        }));
      }

      if (remove.length) {
        this.setState(prevState => ({
          toggleBtns: {
            ...prevState.toggleBtns,
            [button.name]: true,
          },
          hiddenFields: prevState.hiddenFields.filter(field => !remove.includes(field)),
        }));
      }

      return;
    }
    if (button.action === buttonTypes.DIALOG_BOX) {
      return this.getDialogBoxData(button, uniqueID);
    } else if (button.endpoint && button.method) {
      this.setState({
        loading: true,
      });
      let variables = {};
      if (button.variables) {
        variables = button.variables;
      }
      if (button.confirm) {
        variables.confirmed = button.confirm;
      }
      variables = Object.assign({}, variables, this.state.updatedValues);
      dynamicOEButtonCall(button.endpoint, button.method, variables)
        .then(response => {
          delete button.confirm;
          this.setState({
            loading: false,
          });
          if (response.status >= 200 && response.status < 300) {
            if (button.close === false || button.reload) {
              this.props.setRefreshOnClose && this.props.setRefreshOnClose();
              if (this.state.formData.control_panel && this.state.formData.control_panel.reload) {
                const {
                  endpoint,
                  endpoint_type,
                  variables: newVariables,
                  method,
                } = this.state.formData.control_panel.reload;
                this.callAPI(endpoint, endpoint_type, method, newVariables, {}, 'new Form' + new Date().getTime());
              } else if (this.state.formData.corner_control_panel && this.state.formData.corner_control_panel.reload) {
                //This might be wonky - Add some more recognition later
                const {
                  endpoint,
                  endpoint_type,
                  variables: newVariables,
                  method,
                } = this.state.formData.corner_control_panel.reload;
                this.callAPI(endpoint, endpoint_type, method, newVariables, {}, 'new Form' + new Date().getTime());
              }
            } else {
              //console.log(`Form calling success prop ${button.no_refresh}, ${button.refresh_page}`);
              this.props.success(response, !button.no_refresh, button.refresh_page);
            }
          }

          if (response.data.confirm) {
            if (this.props.openDialog) {
              this.props.openDialog(response, this.handleButton.bind(this), button);
            } else {
              this.openDialog(response, this.handleButton.bind(this), button);
            }
          } else {
            if (this.props.openDialog) {
              this.props.openDialog(response);
            } else {
              this.openDialog(response);
            }
          }
        })
        .catch(() => this.setState({ loading: false }));
    } else {
      console.warn('please include endpoint and method');
    }
  }

  getCornerControlPanel() {
    //if there's a control panel, get pull the buttons
    if (this.state.formData.corner_control_panel) {
      let buttons = [];
      let rawButtons = this.state.formData.corner_control_panel.buttons;
      for (let i = 0; i < rawButtons.length; i++) {
        let button = rawButtons[i];
        /* Based on the control_panel coming from the state we then set each
         ** button to either disabled or not. And we use a Class to change
         ** the disabled buttons appearance. */
        let extraClass = '';
        let disabled = false;
        const { corner_control_panel, loading } = this.state;
        if (
          !button.always_enabled &&
          ((button.id && corner_control_panel && corner_control_panel[button.id]) || loading)
        ) {
          disabled = corner_control_panel[button.id] || loading;
          extraClass = ' button-disabled';
        }
        let icon = null;
        if (button.icon) {
          icon = <CustomToolTip iconOnly={button.iconOnly} icon={button.icon} iconClass={button.iconClass} />;
        }
        //In case this is a Login Form then we use the handleLoginButton
        if (this.props.handleLoginButton) {
          buttons.push(
            <abbr title={button.hovertext} key={'button' + button.label + button.id}>
              <button
                className={classNames('formButton nd-button', button.class, extraClass)}
                style={{
                  padding: '5px 15px',
                  textTransform: 'capitalize',
                }}
                disabled={normalizeBoolean(disabled)}
                type="button"
                onClick={() => this.props.handleLoginButton(this.state.updatedValues)}
                value={button.label}
              >
                {icon}
                {button.label}
              </button>
            </abbr>,
          );
        } else if (button.iconOnly) {
          buttons.push(
            <abbr key={button.label} title={button.hovertext}>
              {icon}
            </abbr>,
          );
        } else {
          //For any other case
          buttons.push(
            <abbr key={'button' + button.label + button.id} title={button.hovertext}>
              <button
                className={classNames('formButton nd-button', button.class, extraClass)}
                disabled={normalizeBoolean(disabled)}
                type="button"
                onClick={this.getButtonAction(button)}
                value={button.label}
              >
                {icon}
                {button.label}
              </button>
            </abbr>,
          );
        }
      }
      return <div className="cornerControlPanel">{buttons}</div>;
    } else {
      return null;
    }
  }

  getFormColumns(columns) {
    let new_columns = [];
    //create each column of the form
    for (let k = 0; k < columns.length; k++) {
      let containers = columns[k].containers;

      //within each column, create each form section
      let formSections = [];
      for (let i = 0; i < containers.length; i++) {
        let hiddenContainer = false;
        if (this.state.hiddenFields.includes(containers[i].id)) {
          hiddenContainer = true;
        }
        let rawFields = containers[i].fields;

        //within each form section, create each field
        let fields = [];
        for (let j = 0; j < rawFields.length; j++) {
          let field = rawFields[j];
          let field_id = field.name + field.label + field.input_type + j;
          if (this.state.dynamicId) {
            field_id += this.state.dynamicId;
          }
          field.key = field.name+j;
          let hiddenField = false;
          //only display the field if it is not hidden
          // a field can be hidden if it is of hidden type or if it has been temporarily hidden by a form controller
          if (this.state.hiddenFields.includes(field.id)) {
            hiddenField = true;
          }
          if (field.input_type !== inputTypes.HIDDEN) {
            // if this is not an input field, but rather instructional HTML, then display it statically
            if (field.input_type === inputTypes.HTML) {
              let htmlStyle = {};
              if (field.maxHeight) {
                htmlStyle = {
                  maxHeight: getSizeInPixel(field.maxHeight),
                  overflow: 'auto',
                };
              }
              if (hiddenField) {
                htmlStyle['display'] = 'none';
              }
              if (Array.isArray(field.value)) {
                fields.push(
                  <Records
                    records={field.value}
                    loading={this.state.loading}
                    handleButton={this.handleButton.bind(this)}
                    selectorMode={true}
                  />,
                );
              } else {
                fields.push(
                  <div key={'html' + field_id} className="htmlWrapper" style={htmlStyle}>
                    {field.label && (
                      <abbr key={'label' + field.name} title={field.hovertext} className="htmlLabel">
                        {field.label}
                      </abbr>
                    )}
                    {field.buttonbar && (
                      <Buttonbar
                        buttonbar={field.buttonbar}
                        loading={this.state.loading}
                        handleButton={this.handleButton.bind(this)}
                      />
                    )}
                    <div className="htmlField" dangerouslySetInnerHTML={{ __html: field.value }} />
                  </div>,
                );
              }

              // otherwise, create the input field
            } else {
              //get the field input and label
              fields.push(
                <div className="inputField" key={'field' + field_id} style={hiddenField ? { display: 'none' } : {}}>
                  {/** This will eventually disappear as every input type will
                   * create its own label
                   */
                  }
                  {(field.input_type === inputTypes.DROPDOWN || 
                    field.input_type === inputTypes.DROPDOWN_MULTI ||
		    field.input_type === inputTypes.INPUT_MASK) && (
                      <div
                        style={
                            {
                                display: 'inline-flex',
                                flexWrap: 'nowrap',
                            }
                        }
                      >
                        {field.label && (
                          <abbr key={'label' + field_id} title={field.hovertext}>
                            <label style={{marginRight:'5px'}}className="inputLabel" htmlFor={field.name}>
                              {field.label}
                            </label>
                            {field.icon && <CustomToolTip icon={field.icon} iconClass={field.iconClass} /> }
                          </abbr>
                        )}
                        {field.buttonbar && (
                          <Buttonbar
                            buttonbar={field.buttonbar}
                            loading={this.state.loading}
                            handleButton={this.handleButton.bind(this)}
                          />
                        )}
                      </div>
                    )}
                  {this.getFieldInput(field)}

                </div>,
              );
            }
          }
          //Creates a group of fields that displayed in the same row
          if (field.row_fields && field.row_fields.length > 0) {
            const inputField_style = {
              display: 'inline-block',
              textAlign: 'left',
              marginRight: '5px',
              verticalAlign: 'bottom',
            };
            const inputLabel_style = {
              display: 'block',
              textAlign: 'left',
            };
            let rowStyle = hiddenField ? { display: 'none' } : { display: 'flex', width: '100%' };

            fields.push(
              <div key={'row_field' + field_id} style={rowStyle}>
                {field.row_fields
                  .filter(row_field => this.state.hiddenFields.indexOf(row_field.id) === -1)
                  .map(row_field => {
                    const row_field_id = row_field.name + row_field.label + row_field.input_type + j;
                    if (row_field.input_type === inputTypes.DROPDOWN && row_field.size) {
                      Object.assign(inputField_style, { maxWidth: `${row_field.size}px` });
                    }
                    if (row_field.input_type === inputTypes.HEADER) {
                      //This will just create the Label (Like a Header) with no Input Field for this Row
                      fields.push(
                        <div
                          key={'header' + row_field.label}
                          className="inputField"
                          style={{ marginTop: '5px', fontSize: '1.1em' }}
                        >
                          <label className="inputLabel">{row_field.label}</label>
                        </div>,
                      );
                    } else {
                      return (
                        <div key={'field' + row_field_id} className="inputField" style={inputField_style}>
                          {row_field.buttonbar && (
                            <div style={{ display: 'inline-block' }}>
                              <Buttonbar
                                buttonbar={row_field.buttonbar}
                                loading={this.state.loading}
                                handleButton={this.handleButton.bind(this)}
                              />
                            </div>
                          )}
                          {/** This will eventually disappear as every input type will
                           * create its own label
                           */}
                          {(row_field.input_type === inputTypes.DROPDOWN || 
                            row_field.input_type === inputTypes.DROPDOWN_MULTI) && (
                              <div
                                style={
                                    {
                                        display: 'inline-flex',
                                        flexWrap: 'nowrap',
                                    }
                                }
                              >
                                {row_field.label && (
                                  <abbr key={'label' + field_id} title={field.hovertext}>
                                    <label style={{marginRight:'5px'}}className="inputLabel" htmlFor={row_field.name}>
                                      {row_field.label}
                                    </label>
                                    {row_field.icon && <CustomToolTip icon={row_field.icon} iconClass={row_field.iconClass} /> }
                                  </abbr>
                                )}
                                {row_field.buttonbar && (
                                  <Buttonbar
                                    buttonbar={row_field.buttonbar}
                                    loading={this.state.loading}
                                    handleButton={this.handleButton.bind(this)}
                                  />
                                )}
                              </div>
                            )}
                          {this.getFieldInput(row_field)}
                        </div>
                      );
                    }
                  })}
              </div>,
            );
          }
        }

        let sectionLabel = containers[i].label ? <div className="sectionLabel">{containers[i].label}</div> : null;
        formSections.push(
          <div className="formSection" key={'container' + i} style={hiddenContainer ? { display: 'none' } : {}}>
            {sectionLabel}
            <div className="sectionContent">
              {k === 0 && i === 0 && this.getCornerControlPanel()}
              {fields}
            </div>
          </div>,
        );
      }
      const column = columns[k];
      let columnStyle;
      if (column.maxColumn) {
        columnStyle = {
          width: '100%',
        };
      } else if (column.width) {
        columnStyle = {
          width: `${column.width}px`,
        };
      } else {
        columnStyle = {};
      }

      new_columns.push(
        <div className="formColumn" key={'formColumn' + k} style={columnStyle}>
          {formSections}
        </div>,
      );
    }
    return new_columns;
  }

  // create the form elements from the JSON
  getFormElements() {
    if (this.state.formData) {
      let formElements = '';

      //get the fields
      if (this.state.formData.columns) {
        let columns = [];
        let formClass = '';
        //extract class to apply to formContent
        formClass = this.state.formData.class || '';
        columns = this.getFormColumns(this.state.formData.columns);

        formElements = (
          <div className={'formContent ' + formClass}>
            <div className="formColumns">
              {columns}
              {this.props.miniForm && this.getControlPanel()}
            </div>
            {!this.props.miniForm && this.getControlPanel()}
          </div>
        );
      }
      if (this.state.formData.rows) {
        let rows = [];
        let formClass = '';
        //extract class to apply to formContent
        formClass = this.state.formData.class || '';

        for (let i = 0; i < this.state.formData.rows.length; i++) {
          let columns = this.getFormColumns(this.state.formData.rows[i].columns);
          rows.push(<div className="formColumns">{columns}</div>);
        }

        formElements = (
          <div className={'formContent ' + formClass}>
            <div className="formRows">
              {rows}
              {this.props.miniForm && this.getControlPanel()}
            </div>
            {!this.props.miniForm && this.getControlPanel()}
          </div>
        );
      }

      return formElements;
    }
  }

  //Close the DialogBox and delete the data from the state
  closeBox() {
    this.setState({
      dialog_box: {
        data: null,
        show: false,
        dialog_type: null,
        controller: null,
      },
    });
  }

  /*
   ** param {Object} - data
   ** param {String} - dialog_type
   */
  openDialogBox(data, controller, uniqueID) {
    this.setState({
      loading: false,
      dialog_box: {
        uniqueID,
        data: data,
        show: true,
        controller: controller,
      },
    });
  }

  /*
   ** Extract the data from the controller and pass the data to the DialogBox
   **
   ** param {Number/String/Boolean} - newValue
   ** param {Object} - controller
   ** param {Object} - extra
   */
  getDialogBoxData(controller, newValue, extra = {}) {
    if (!newValue) {
      return null;
    }
    if (controller.endpoint) {
      let params = controller.variables || {};
      if (this.state.updatedValues) {
        //params passed from Form.js
        let keys = Object.keys(this.state.updatedValues);
        for (let key of keys) {
          if (!/<[a-z][\s\S]*>/i.test(this.state.updatedValues[key])) {
            //ignore object with HTML tags
            params = Object.assign({}, params, {
              [key]: this.state.updatedValues[key],
            });
          }
        }
      }
      params = Object.assign({}, params, extra);
      //Display the loading animation
      this.setState({ loading: true });
      get(params, `/${controller.endpoint}`, 'Fetching data for DialogBox')
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data && response.data.control_panel) {
              /* If there's a control_panel it means that we're controlling the state of one or more
               ** buttons and we need to format it and the correct way and update the state. */
              let control_panel = {};
              Object.keys(response.data.control_panel).map(key => {
                control_panel = Object.assign({}, control_panel, {
                  [key]: response.data.control_panel[key].disabled,
                });
                return key;
              });
              this.setState({ control_panel });
            }
            if (response.data && response.data.corner_control_panel) {
              /* If there's a corner_control_panel it means that we're controlling the state of one or more
               ** buttons and we need to format it and the correct way and update the state. */
              let corner_control_panel = {};
              Object.keys(response.data.corner_control_panel).map(key => {
                corner_control_panel = Object.assign({}, corner_control_panel, {
                  [key]: response.data.corner_control_panel[key].disabled,
                });
                return key;
              });
              this.setState({ corner_control_panel });
            }
            if (response.data && (response.data.config || (response.data.data && response.data.data.config))) {
              this.openDialogBox(response.data, controller, Object.values(newValue)[0]);
              /* TODO: Find a better way to do this */
            } else if (controller.dialog_type === 'html' && response && response.data && response.data.data) {
              this.openDialogBox(response.data, controller, Object.values(newValue)[0]);
            } else if (response.data.fullmsg) {
                this.openDialog(response);
              /* If it's a successful response (200 - 299) but the format is not correct, must likely is a
               ** back-end error. So we call the APIDialog and this will set LOADING to false.*/
              /* PJW 31May23: In the case of an empty response, data seems to have a random string. 
               * This does not happen in older versions of react. I'm supressing this output. 
               * 
            } else if (typeof response.data === 'string') {
              if (typeof response.data === 'string') {
                this.openDialog({
                  //faking the correct response when there's an Error in the API
                  data: {
                    errmsg: response.data,
                  },
                });
              } else {
                this.openDialog(response);
              }
               */
            } else {
              console.warn('No API Dialog Response or DialogBox sent from back-end');
              this.setState(
                {
                  loading: false,
                },
                this.closeBox.bind(this),
              );
            }
          } else {
            //If it wasn't a successful response then let's open the APIDialog
            this.openDialog(response);
          }
        })
        .catch(err => {
          /* When this request fails we check to see if there's a control_panel, if it is it means
           ** we need to update the state of the control_panel in order to disable/enable them. This is
           ** how we prevent the user from creating a user that will come back as an error from the back-end
           ** because the user is not allowed.
           **
           ** At the same time we stop the loading state and we clear the dialog_box, hiding it from the user since it's
           ** no longer relevant.
           **
           ** After this updates have taken place, we pass the response to openDialog to display any message as an APIDialog.
           */
          let control_panel = {};
          if (err.response && err.response.data) {
            if (err.response.data && err.response.data.control_panel) {
              /* If there's a control_panel it means that we're controlling the state of one or more
               ** buttons and we need to format it and the correct way and update the state. */
              Object.keys(err.response.data.control_panel).map(key => {
                control_panel = Object.assign({}, control_panel, {
                  [key]: err.response.data.control_panel[key].disabled,
                });
                return key;
              });
            }
          }
          let corner_control_panel = {};
          if (err.response && err.response.data) {
            if (err.response.data && err.response.data.corner_control_panel) {
              /* If there's a corner_control_panel it means that we're controlling the state of one or more
               ** buttons and we need to format it and the correct way and update the state. */
              Object.keys(err.response.data.corner_control_panel).map(key => {
                corner_control_panel = Object.assign({}, corner_control_panel, {
                  [key]: err.response.data.corner_control_panel[key].disabled,
                });
                return key;
              });
            }
          }

          if (Object.keys(control_panel) && Object.keys(corner_control_panel)) {
            this.setState(
              {
                loading: false,
                control_panel,
                corner_control_panel,
                dialog_box: {
                  data: null,
                  show: false,
                  controller: null,
                },
              },
              () => this.openDialog(err.response),
            );
          } else if (Object.keys(control_panel)) {
            this.setState(
              {
                loading: false,
                control_panel,
                dialog_box: {
                  data: null,
                  show: false,
                  controller: null,
                },
              },
              () => this.openDialog(err.response),
            );
          } else if (Object.keys(corner_control_panel)) {
            this.setState(
              {
                loading: false,
                corner_control_panel,
                dialog_box: {
                  data: null,
                  show: false,
                  controller: null,
                },
              },
              () => this.openDialog(err.response),
            );
          } else {
            this.setState(
              {
                loading: false,
                dialog_box: {
                  data: null,
                  show: false,
                  controller: null,
                },
              },
              () => this.openDialog(err.response),
            );
          }
        });
    } else {
      this.openDialogBox(controller, controller, Object.values(newValue)[0]);
    }
  }

  callAPI(endpoint, endpoint_type, method, variables = {}, extra = {}, dynamicId) {
    let params = Object.assign({}, this.state.updatedValues, variables);
    //Adding extra parameter, usually the Changing Dropdown
    Object.assign(params, extra);
    if (method === 'GET') {
      params = {
        ...params,
        ...this.state.toggleBtns,
      };
      get(params, `/${endpoint}`, 'Requesting new Form').then(response => {
        if (response.status === 200 && response.data && response.data.form) {
          this.init(response.data.form, dynamicId); //Initialize the Form Again
        } else if (response.status === 200 && response.data && response.data.data) {
          this.init(response.data.data, dynamicId); //Initialize the Form Again
        } else {
          this.openDialog(response);
        }
      }).catch(e => console.log('Failed with error:', e));
    } else {
      console.warn('wrong method');
    }
  }

  // if the given field is a form controller, update the forms state accordingly given its new value
  // TODO: return value instead of setting state, set state externally so that we can use this on initialization
  handleControllerShift(field, newValue) {
    //TODO: think of a better way to do this
    if (field && field.form_controllers) {
      let hiddenFields = this.state.hiddenFields || [];
      for (let i = 0; i < field.form_controllers.length; i++) {
        let controller = field.form_controllers[i];
        //console.log(controller.on_value, newValue);
        // eslint-disable-next-line
        // ES6 would prefer === was used everywhere, but the API isn't always careful about
        // using the same type (string, int) for the on_value and options.
        let match = Array.isArray(controller.on_value)
          ? controller.on_value.includes(newValue)
          : controller.on_value == newValue;
        if (
          (controller.control_type === formControlTypes.VISIBILITY_ON && !match) ||
          (controller.control_type === formControlTypes.VISIBILITY_OFF && match)
        ) {
          //TODO: make this a switch statement with more types
          Array.prototype.push.apply(hiddenFields, controller.controlled);
        } else if (controller.controlled) {
          let fieldFilter = function (fieldName) {
            return controller.controlled.indexOf(fieldName) === -1;
          };
          hiddenFields = hiddenFields.filter(fieldFilter);
        }
        if (controller.control_type === formControlTypes.DIALOG) {
          this.getDialogBoxData(controller, newValue, {
            [field.name]: newValue,
          }); //including the new Name:Value pair
        }
        if (controller.control_type === formControlTypes.REPLACE_OPTS) {
          const { endpoint, endpoint_type, method, variables } = controller;
          if (endpoint && endpoint_type && method) {
            /* The dynamicId is the key that will be used to create a new Form when we try
             ** to replace the existing form. By using this we tell React that we want this
             ** Form to be re-rendered.
             **
             ** Check getFormElements to see how this is used. */
            const dynamicId = field.name + (/<[a-z][\s\S]*>/i.test(newValue) ? i : newValue + i);
            const extra = { [field.name]: newValue }; //including the new Name:Value pair
            this.callAPI(endpoint, endpoint_type, method, variables, extra, dynamicId);
          } else {
            console.warn('Need endpoint, endpoint_type and method');
          }
        }
      }
      this.setState({
        hiddenFields: hiddenFields,
      });
    }
  }

  getOnChange(event, field) {
    let newValue = event && event.target ? event.target.value : event;
    this.handleControllerShift(field, newValue);
    let updatedValues = this.state.updatedValues;
    updatedValues[field.name] = newValue;
    this.setState({ updatedValues: updatedValues });
  }

  getButtonAction(button) {

    return function () {
      if ( !button ) return; // NOTE Early return

      let variables = Object.assign({}, button.variables || {}, this.state.updatedValues);
      variables.confirmed = button.confirm;

      // if the button requires confirmation, submit with confirm=1 and then open a dialog w the response
      // otherwise, go ahead and submit the actual button call
      let successFunc = function (response) {
        if (response.data && !response.data.confirm) {
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
          if (this.props.refreshReports && button.reload_report) {
            this.props.refreshReports && this.props.refreshReports(button.reload_report);
          }
          if (button.close === false) {
            this.props.setRefreshOnClose && this.props.setRefreshOnClose();
          } else {
            this.props.success && this.props.success(response, !button.no_refresh, button.refresh_page);
          }
        }
        /* For cases like DialogBox where we have a Form, when the Form is sent the DialogBox will
         ** close on a success response, unmounting the Form, for that reason we need to be able to
         ** open the APIDialogResponse outside of the Form component so it doesn't throw an error of
         ** setting the state on an unmount component (Form).
         */
        if (this.props.openDialog) {
          this.props.openDialog(response, this.getButtonAction.bind(this), button);
        } else {
          this.openDialog(response, this.getButtonAction.bind(this), button);
        }

        this.setState({
          loading: false,
        });
      };
      if (button.action === 'dialog') {
        return this.getDialogBoxData(button, 'dialog');
      }
      if (this.props.variables) {
        variables = Object.assign({}, variables, this.props.variables);
      }

      this.setState({
        loading: true,
      });

      if ((button.close === false || button.reload) && this.state.formData.control_panel.reload) {
        const {
          endpoint,
          endpoint_type,
          variables: reloadVariables,
          method,
        } = this.state.formData.control_panel.reload;
        this.props.setRefreshOnClose && this.props.setRefreshOnClose();
        dynamicOEButtonCall(button.endpoint, button.method, variables).then(response => {
          // PJW 28Mar23 I think we need to handle a client error too - 406 is HTTP 'Not Acceptable'
          // but on that response, don't reload so client can correct the error and resubmit
          if ((response.status >= 200 && response.status <= 400) || response.status === 406) {
            this.setState({
              loading: false,
            });
            if (this.props.openDialog) {
              this.props.openDialog(response, this.getButtonAction.bind(this), button);
            } else {
              this.openDialog(response, this.getButtonAction.bind(this), button);
            }
            const dynamicId = 'new Form ' + new Date().getTime();
            if (response.status < 400 && !response.data.confirm) {
              // Go ahead and reload
              this.callAPI(endpoint, endpoint_type, method, reloadVariables, {}, dynamicId);
            }
          }
        });
      } else if ((button.close === false || button.reload) && this.state.formData.corner_control_panel.reload) {
        const {
          endpoint,
          endpoint_type,
          variables: reloadVariables,
          method,
        } = this.state.formData.corner_control_panel.reload;
        this.props.setRefreshOnClose && this.props.setRefreshOnClose();
        dynamicOEButtonCall(button.endpoint, button.method, variables).then(response => {
          // PJW 28Mar23 I think we need to handle a client error too - 406 is HTTP 'Not Acceptable'
          // but on that response, don't reload so client can correct the error and resubmit
          if ((response.status >= 200 && response.status <= 400) || response.status === 406) {
            this.setState({
              loading: false,
            });
            if (this.props.openDialog) {
              this.props.openDialog(response, this.getButtonAction.bind(this), button);
            } else {
              this.openDialog(response, this.getButtonAction.bind(this), button);
            }
            const dynamicId = 'new Form ' + new Date().getTime();
            if (response.status < 400 && !response.data.confirm) {
              // Go ahead and reload
              this.callAPI(endpoint, endpoint_type, method, reloadVariables, {}, dynamicId);
            }
          }
        });
      } else {
        dynamicOEButtonCall(button.endpoint, button.method, variables).then(successFunc.bind(this));
      }
    }.bind(this);
  }

  modalClosed() {
    if ( this.props.onClose ) { 
      this.props.onClose();
    } else {
      this.props.setRefreshOnClose && this.props.setRefreshOnClose();
      if (this.state.formData.control_panel && this.state.formData.control_panel.reload) {
        const {
          endpoint,
          endpoint_type,
          variables: newVariables,
          method,
        } = this.state.formData.control_panel.reload;
        this.callAPI(endpoint, endpoint_type, method, newVariables, {}, 'new Form' + new Date().getTime());
      }
    }
  }

  //returns the input component for a given field
  getFieldInput(field) {
    let fieldValue = this.state.updatedValues ? this.state.updatedValues[field.name] : field.value;
	

    //switch on this field's input type
    switch (field.input_type) {
      case inputTypes.MODAL:
        return(
          <Modal
          key={"modal" + field.name}
          label={field.name}
          url={field.value}
          img={field.img}
          onClose={this.modalClosed.bind(this)}
          />
        );
      //if it's a checkbox, return a checkbox input
      case inputTypes.HTML:
        let htmlStyle = {};
        if (field.maxHeight) {
            htmlStyle = {
                maxHeight: getSizeInPixel(field.maxHeight),
                overflow: 'auto',
            };
        }
        return (
            <div key={'html' + field.id} className="htmlWrapper" style={htmlStyle}>
                    {field.label && (
                      <abbr key={'label' + field.name} title={field.hovertext} className="htmlLabel">
                        {field.label}
                      </abbr>
                    )}
                <div className="htmlField" dangerouslySetInnerHTML={{ __html: field.value }} />
            </div>
        );

      case inputTypes.CHECKBOX:
        return (
          <MuiCheckbox
            key={'cbox' + field.name}
            {...field}
            label={field.label}
            icon={field.icon}
            iconClass={field.iconClass}
            name={field.name}
            value={field.value}
            selected={field.selected}
            hovertext={field.hovertext}
            autoComplete={field.autocomplete === 'off' ? 'off' : 'on'}
            onChange={e => this.getOnChange(e, field)}
          />
        );

      //if it's a time select, return a TimePicker component
      case inputTypes.TIME_SELECT:
        return (
          <WrappedTimePicker
            key={'clock' + field.key}
            label={field.label}
            icon={field.icon}
            iconClass={field.iconClass}
            name={field.name}
            format={field.format}
            value={field.value ? moment(field.value, 'HH:mm') : null}
            minuteStep={field.minuteStep}
            hourStep={field.hourStep}
            hovertext={field.hovertext}
            showSecond={false}
            onChange={e => this.getOnChange(e, field)}
          />
        );

      case inputTypes.DATE_TIME:
        return (
          <WrappedDatetime
            key={'datetime' + field.key}
            name={field.name}
            label={field.label}
            icon={field.icon}
            iconClass={field.iconClass}
            buttonbar={field.buttonbar}
            handleButton={this.handleButton.bind(this)}
            format={field.format}
            value={field.value ? moment(field.value) : null}
            minDate={field.range ? moment(field.range.start) : moment(0)}
            maxDate={field.range ? moment(field.range.end) : null}
            twelveHourClock={field.twelveHourClock}
            minuteStep={field.minuteStep}
            hourStep={field.hourStep}
            hovertext={field.hovertext}
            onChange={e => this.getOnChange(e, field)}
          />
        );

      case inputTypes.DATE_SELECT:
        if (field !== null) {
        return (
          <WrappedDatetime
            key={'date' + field.key}
            field_key={field.key}
            name={field.name}
            label={field.label}
            icon={field.icon}
            iconClass={field.iconClass}
            buttonbar={field.buttonbar}
            handleButton={this.handleButton.bind(this)}
            format={'no_time'}
            value={field.value ? moment(field.value) : null}
            minDate={field.range ? moment(field.range.start) : moment(0)}
            maxDate={field.range ? moment(field.range.end) : moment()}
            hovertext={field.hovertext}
            onChange={e => this.getOnChange(e, field)}
          />
        );
        }

      case inputTypes.DATE_RANGE:
        return (
          <MuiDateRange
            key={'calRange' + field.name}
            startLabel={field.start_label}
            endLabel={field.end_label}
            icon={field.icon}
            iconClass={field.iconClass}
            startDefault={moment(field.startDefault)}
            endDefault={moment(field.endDefault)}
            start={field.range ? moment(field.range.start) : null}
            end={field.range ? moment(field.range.end) : null}
            hovertext={field.hovertext}
            onChange={e => this.getOnChange(e, field)}
          />
        );

      //if it's a dropdown or a multi dropdown, return a Select component with "multi" set appropriately
      case inputTypes.DROPDOWN:
      case inputTypes.DROPDOWN_MULTI: {
        if (!(field && field.opts && Array.isArray(field.opts))) {
          console.warn('no opts, or opts is not an array - Dropdown');
          return null;
        }
        let options = field.opts.map(opt => ({
          value: opt.id,
          label: opt.label,
          details: opt.details,
        }));

        let isMulti = field.input_type === inputTypes.DROPDOWN_MULTI;
        //for now default multi value to an empty array because the value property from the api is formatted incorrectly
        let activeElement = 'dropdown';
        let foundOther = false;
        if (!isMulti && field.value !== '' && parseInt(field.value) !== 0) {
          activeElement = 'input';
          options.forEach(function (opt) {
            if (opt.value == field.value) activeElement = 'dropdown';
            if (opt.value === 'other') {
              foundOther = true;
            }
          });
          if (!foundOther) activeElement = 'dropdown';
        }

        return (
          <WrappedSelect
            multi={isMulti}
            name={field.name}
            value={field.value}
            activeElementType={activeElement}
            options={options}
            size={field.size}
            autocomplete={field.autocomplete}
            hovertext={field.hovertext}
            className={'customSelect' + (isMulti ? ' multiple' : '')}
            onChange={e => this.getOnChange(e, field)}
          />
        );
      }
      case inputTypes.TEXT_AREA:
        return (
          <MuiTextArea
            key={field.id || 'textarea_' + field.name + (field.value ? field.value.slice(0, 10) : '')}
            name={field.name}
            label={field.label}
            icon={field.icon}
            iconClass={field.iconClass}
            buttonbar={field.buttonbar}
            handleButton={this.handleButton.bind(this)}
            rowsMax={field.rows}
            readOnly={field.readonly}
            placeholder={field.placeholder}
            autoComplete={field.autocomplete}
            defaultValue={field.value}
            hovertext={field.hovertext}
            onChange={e => this.getOnChange(e, field)}
          />
        );
      case inputTypes.RADIO:
        return (
          <MuiRadioButton
            key={'radio' + field.name + field.label}
            row={field.row}
            name={field.name}
            label={field.label}
            icon={field.icon}
            iconClass={field.iconClass}
            buttonbar={field.buttonbar}
            options={field.opts}
            value={field.value}
            hovertext={field.hovertext}
            onChange={e => this.getOnChange(e, field)}
          />
        );
      case inputTypes.PASSWORD:
        return (
          <MuiTextField
            key={'input_pass' + field.name}
            type="password"
            label={field.label}
            icon={field.icon}
            iconClass={field.iconClass}
            name={field.name}
            id={field.name}
            size={field.size}
            maxLength={field.max}
            readOnly={field.readonly}
            className="default-input"
            defaultValue={field.value}
            autoComplete={field.autocomplete}
            hovertext={field.hovertext}
            onChange={e => this.getOnChange(e, field)}
            onKeyPress={this.handleKeyPressed.bind(this)}
          />
        );
      case inputTypes.INPUT_MASK:
        return (
          // TODO: create dedicated component class for InputMask
          <abbr title={field?.hovertext}>
            <InputMask
              formatChars={{
                9: '[0-9]',
                a: '[A-Za-z]',
                '*': '[A-Za-z0-9]',
              }}
              maskChar=" "
              key={'input' + field.name}
              label={field.label}
              name={field.name}
              id={field.name}
              size={field.size}
              mask={field.mask}
              maxLength={field.max}
              readOnly={field.readonly}
              className="default-input"
              defaultValue={field.value}
              autoComplete={field.autocomplete ? 'on' : 'off'}
              hovertext={field.hovertext}
              onChange={e => this.getOnChange(e, field)}
              onKeyPress={this.handleKeyPressed.bind(this)}
            />
            {field.icon && <CustomToolTip icon={field.icon} iconClass={field.iconClass} /> }
          </abbr>
        );

      //if it's a text box, typeless, or of a type we don't handle, return a text box
      default:
        return (
          <MuiTextField
            key={'input' + field.name}
            type="text"
            label={field.label}
            icon={field.icon}
            iconClass={field.iconClass}
            name={field.name}
            id={field.name}
            size={field.size}
            maxLength={field.max}
            readOnly={field.readonly}
            className="default-input"
            defaultValue={
              this.props.location.pathname === '/login' && this.props.localStorageEmail
                ? this.props.localStorageEmail
                : field.value
            }
            autoComplete={field.autocomplete}
            hovertext={field.hovertext}
            onChange={e => this.getOnChange(e, field)}
            onKeyPress={this.handleKeyPressed.bind(this)}
          />
        );
    }
  }

  //This will allow the Login Form to submit the values
  //when the user hits Enter
  handleKeyPressed(e) {
    if (e.key === 'Enter' && this.props.handleLoginButton) {
      this.props.handleLoginButton(this.state.updatedValues);
    }
  }

  getControlPanel() {
    //if there's a control panel, get pull the buttons
    if (this.state.formData.control_panel) {
      let buttons = [];
      let rawButtons = this.state.formData.control_panel.buttons;
      for (let i = 0; i < rawButtons.length; i++) {
        let button = rawButtons[i];
        /* Based on the control_panel coming from the state we then set each
         ** button to either disabled or not. And we use a Class to change
         ** the disabled buttons appearance. */
        let extraClass = '';
        let disabled = false;
        const { control_panel, loading } = this.state;
        if (!button.always_enabled && ((button.id && control_panel && control_panel[button.id]) || loading)) {
          disabled = control_panel[button.id] || loading;
          extraClass = ' button-disabled';
        }
        let icon = null;
        if (button.icon) {
          icon = <CustomToolTip icon={button.icon} iconClass={button.iconClass} />;
        }
        //In case this is a Login Form then we use the handleLoginButton
        if (this.props.handleLoginButton) {
          if (button.action === buttonTypes.REDIRECT) {
            buttons.push(
              <abbr title={button.hovertext} key={'button' + button.label + button.id}>
                <button
                  className={classNames('formButton nd-button', button.class, extraClass)}
                  style={{
                    padding: '5px 15px',
                    textTransform: 'capitalize',
                  }}
                  disabled={normalizeBoolean(disabled)}
                  type="button"
                  onClick={() => this.props.history.push(button.redirect)}
                  value={button.label}
                >
                  {icon}
                  {button.label}
                </button>
              </abbr>,
            );
          } else if (button.icon === 'question-sign') {
            buttons.push(
              <abbr title={button.hovertext} key={button.label + button.icon + button.id}>
                {icon}
              </abbr>,
            );
          } else {
            buttons.push(
              <abbr title={button.hovertext} key={'button' + button.label + button.id}>
                <button
                  className={classNames('formButton nd-button', button.class, extraClass)}
                  style={{
                    padding: '5px 15px',
                    textTransform: 'capitalize',
                  }}
                  disabled={normalizeBoolean(disabled)}
                  type="button"
                  onClick={() => this.props.handleLoginButton(this.state.updatedValues)}
                  value={button.label}
                >
                  {icon}
                  {button.label}
                </button>
              </abbr>,
            );
          }
        } else {
          //For any other case
          buttons.push(
            <abbr title={button.hovertext} key={'button' + button.label + button.id}>
              <button
                className={classNames('formButton nd-button', button.class, extraClass)}
                disabled={normalizeBoolean(disabled)}
                type="button"
                onClick={this.getButtonAction(button)}
                value={button.label}
              >
                {icon}
                {button.label}
              </button>
            </abbr>,
          );
        }
      }
      return <div className="buttonContainer">{buttons}</div>;
    } else {
      return null;
    }
  }

  renderDialogBox() {
    if (this.state.dialog_box.show && this.state.dialog_box.data) {
      const defaultPosition = this.props.fromDialogBox
        ? {
            position: 'absolute',
            right: '100%',
            width: '100%',
          }
        : {};
      return (
        <DialogBox
          key={'dialog_box_form' + this.state.dialog_box.uniqueID}
          controller={this.state.dialog_box.controller}
          data={this.state.dialog_box.data}
          fromDialogBox={this.props.fromDialogBox}
          dialog_type={this.state.dialog_box.controller.dialog_type}
          onClose={this.closeBox.bind(this)}
          openDialog={this.openDialog.bind(this)}
          defaultPosition={isMobile ? {} : defaultPosition}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    if (this.props.miniForm) {
      return (
        <div className="form miniForm" style={this.props.extraStyle}>
          <APIResponseDialog
            open={this.state.dialog.open}
            response={this.state.dialog.response}
            button={this.state.dialog.button}
            buttonCallback={this.state.dialog.buttonCallback}
            notify={true}
          />
          {this.state.formData && this.getFormElements()}
          {this.state.formData && this.renderDialogBox()}
          {this.state.loading && (
            <div style={{ position: 'absolute' }}>
              <Loading type="spinningBubbles" color="#207cca" height="100px" width="100px" />
            </div>
          )}
        </div>
      );
    } else if (this.props.standAlone) {
      return (
        <div className="form standAlone">
          <div className="contentCard" style={this.props.extraStyle}>
            <div className="cardBody">
              <APIResponseDialog
                open={this.state.dialog.open}
                response={this.state.dialog.response}
                button={this.state.dialog.button}
                buttonCallback={this.state.dialog.buttonCallback}
                notify={true}
              />
              {this.state.formData && this.getFormElements()}
              {this.state.formData && this.renderDialogBox()}
              {this.state.loading && (
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                  }}
                >
                  <Loading type="spinningBubbles" color="#207cca" height="100px" width="100px" />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="form" style={this.props.extraStyle}>
          <APIResponseDialog
            open={this.state.dialog.open}
            response={this.state.dialog.response}
            button={this.state.dialog.button}
            buttonCallback={this.state.dialog.buttonCallback}
            notify={true}
          />
          {this.state.formData && this.getFormElements()}
          {this.state.formData && this.renderDialogBox()}
          {this.state.loading && (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
              }}
            >
              <Loading type="spinningBubbles" color="#207cca" height="100px" width="100px" />
            </div>
          )}
        </div>
      );
    }
  }
}

export default withRouter(Form);

function normalizeBoolean(value) {
  if (value === 'false') return false;
  return Boolean(value);
}
