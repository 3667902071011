import React, { useEffect, useState } from 'react';
import { Route, withRouter, Switch, Redirect, Link, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
//import MailIcon from '@mui/icons-material/Mail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

//import NotificationsIcon from '@mui/icons-material/Notifications';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import TabNavigation from './TabNavigation';
import { getAdminTabs, getUserTabs, getLogoHeader, getOrgMan } from '../../actions/API';
import { NotificationsInit } from '../../actions/NotificationActions';
// import AdminPage from '../../containers/AdminPage';
import TimeClockPage from '../../containers/TimeClockPage';
// import OrgManPage from '../../containers/OrgManPage';
// import UserPage from '../../containers/UserPage';
import Login from '../Login';
import ResetPasswordContainer from '../../containers/ResetPasswordContainer';
import ResetContainer from '../../containers/ResetContainer';
import FormContainer from '../../containers/FormContainer';
import AuthContainer from '../../containers/AuthContainer';
import Drawer from './Drawer';
import Router from './Router';
import Ribbon from '../Common/Ribbon';
import { isMobile } from 'react-device-detect';
import { makeStyles } from 'tss-react/mui';

const iconsSVG = require('../../constants/iconsSVG.js');
const iconTypes = require('../../constants/iconTypes.js');

const drawerWidth = 240;

const useStyles = makeStyles()(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  appBarIcons: {
    color: '#000',
    marginRight: '10px',
  },

}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  background: `url(${require('../../img/header_background_main.jpg')}) no-repeat`,
  backgroundSize: 'cover',
  boxShadow: '2px 2px 5px #000',
  minHeight: '56px !important',
  height: '56px',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const cookie = new Cookies();

const Header = props => {
  const location = useLocation();

  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const anchorRef = React.useRef(null);

  const { classes } = useStyles();
  const [userTabs, setUserTabs] = React.useState([]);
  const [adminTabs, setAdminTabs] = React.useState([]);
  const [orgmanTabs, setOrgmanTabs] = React.useState([]); // Temporary solution for accessing orgman
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [logo, setLogo] = React.useState(process.env.REACT_APP_HEADER_LOGO);
  const [browserMessages, setBrowserMessages] = useState(0);
  const [calloutClass, setCalloutClass] = useState('svg-img svg-img-white');
  const [newCallouts, setNewCallouts] = useState(0);


  const open = Boolean(anchorEl);
  const isTimeclock = cookie.get('ndUserType') === 'timeclock';
  const isResetPassword = searchParams.get('op') === 'reset';
  const isSSO = searchParams.get('login') !== null;
  const isSSOWithUserToken = searchParams.get('user_id') && searchParams.get('token');
  const isOrgman = location.pathname == '/orgman';
  const incrementBrowserMessages = () => {setBrowserMessages(browserMessages+1);}
  const incrementCallouts = () => {
    setNewCallouts(newCallouts+1); 
    setCalloutClass('svg-img svg-img-red')
  }

  useEffect(() => {
    if (isResetPassword || isSSO || isSSOWithUserToken) {
	    console.log("Clearing key because");
	    console.log(isResetPassword);
	    console.log(isSSO);
	    console.log(isSSOWithUserToken);
      props.globalActions.clearApiKey();
    }
  }, []);

  useEffect(() => {
    // DEV-918 always reload tabs to get new preLoadedData
    props.isLoggedIn && loadData();
  }, [props.history.location, props.isLoggedIn, getLogoHeader, logo]);

  if (process.env.REACT_APP_PUSH_NOTIFICATIONS === 'true') {
    NotificationsInit(props,incrementBrowserMessages,incrementCallouts);
  }

  function loadData() {
    Promise.all([getUserTabs(), getAdminTabs(), getOrgMan(), getLogoHeader()]).then(
      ([userData, adminData, orgmanData]) => {
        if ((userData && userData.data === 'Unauthorized') || (adminData && adminData.data === 'Unauthorized') || (orgmanData && orgmanData.data === 'Unauthorized')) {
          cookie.remove('ndUserType', { path: '/' });
          cookie.remove('ndAPIKey', { path: '/' });
          props.history.push('/login');
        } else {
          try {
            if (!isTimeclock) {
              const { usertabs, header } = userData?.data?.config;
              setLogo(header?.logo_filename || '');
              setUserTabs(usertabs || []);
              setUserTabs(usertabs || []);
            }
            const { admintabs } = adminData?.data?.config;
            setAdminTabs(admintabs || []);
            const orgmantabs = orgmanData?.data?.config.admintabs; 
            setOrgmanTabs(orgmantabs || []);
          } catch (e) {
            console.error('There was a problem loading the tabs', e);
          }
        }
      },
    );
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const submitLogout = () => {
    handleClose();
    props.globalActions.logout().then(() => {
      setUserTabs([]);
      setAdminTabs([]);
      setOrgmanTabs([]);
      props.history.replace('/login');
    });
  };

  const refreshCallouts = (setNewCallouts,setCalloutClass) => {
    setNewCallouts(0);
    setCalloutClass('svg-img svg-img-white');
    props.globalActions.fetchCallouts().then(() => {
      props.history.replace('/user/dashboard');
    });
  };

  const refreshNotifications = (setBrowserMessages) => {
    setBrowserMessages(0);
    props.globalActions.fetchNotifications().then(() => {
      props.history.replace('/user/dashboard');
    });
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const getRouteWithTabs = ({ route = '', topmenu, label }, index) => {

    return (
      <Route
        key={route.split('/').join('') + label + index}
        path={`/${route}`}
        render={routerProps => <TabNavigation globalActions={props.globalActions} tabData={topmenu} {...routerProps} />}
      />
    );
  };

  const getRoute = (tab, generalTabs) => {
    return (
      <Route
        key={tab.route.split('/').join('') + tab.label}
        path={`/${tab.route}`}
        render={() => (
          <Router
            content={tab}
            preLoadedData={tab.data ? { data: tab.data } : undefined}
            tabs={generalTabs}
            globalActions={props.globalActions}
          />
        )}
      />
    );
  };

  const getAllRoutes = tabs => {
    const routes = tabs.map((tab, index) => {
      if (tab.ribbon) {
        // CONTENT WITH RIBBONS
        return (
          <Route
            key={tab.route.split('/').join('') + tab.label + index}
            path={`/${tab.route}`}
            render={routerProps => <Ribbon data={tab.ribbon} globalActions={props.globalActions} {...routerProps} />}
          />
        );
      }

      if (tab.topmenu) {
        // CONTENT WITH TABS
        return getRouteWithTabs(tab, index);
      }

      // STRAIGHT TO THE "ROUTER / COMPONENTPICKER"
      return getRoute(tab, tabs);
    });

    // Add 404 route
    if (routes.length) {
      return routes;
    }

    return [];
  };

  const handleToggle = () => {
    setOpenMenu(prevOpen => !prevOpen);
  };

  const handleCloseMenu = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar open={drawerOpen}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', background: 'white' }}>
            {props.isLoggedIn && !drawerOpen && (
              <IconButton edge="start" color={'black'} aria-label="menu" onClick={handleDrawerOpen}>
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
            <Box>
              <Link to={'/user/dashboard'}>
                <img
                  id="headerLogo"
                  alt="app logo"
                  src={require('../../img/' + logo)}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                />
              </Link>
            </Box>
            <Box sx={{ color: 'Highlight', flexGrow: 1, textAlign: 'center', fontWeight: 'bold', fontSize: '2rem' }}>
              <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
                {props.displayData?.org_label}
              </Box>
            </Box>
            {props.isLoggedIn && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}>
                { ( process.env.REACT_APP_PUSH_NOTIFICATIONS === 'true' ) && ( 
                <>
                  <IconButton aria-label="show new messages" color="inherit"
                    onClick={() => refreshCallouts(setNewCallouts,setCalloutClass)}>
                    <Badge badgeContent={newCallouts} color="secondary">
                      <img className={classNames(calloutClass)} src={iconsSVG.CALLOUT} style={{ width: '16px', height: '16px', marginRight: '8px' }} />;
                    </Badge>
                  </IconButton>
                { /*<IconButton aria-label="show new notifications" color="inherit"
                    onClick={() => refreshNotifications(setBrowserMessages) }>
                    <Badge badgeContent={browserMessages} color="secondary">
                      <NotificationsIcon className={classes.appBarIcons} />
                    </Badge>
                  </IconButton>
                */ }
                </>
                )}
                <IconButton
                  ref={anchorRef}
                  aria-label="account of current user"
                  aria-controls={openMenu ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  sx={{
                    cursorPointer: 'none',
                    fontWeight: 500,
                    color: '#0071B5',
                    fontSize: '16px',
                  }}>
                  {' '}
                  {props.displayData && props.displayData.roster_label}
                </IconButton>
                <img
                  id="headerLogo"
                  alt="app logo"
                  src={require('../../img/vertcleSilverline.png')}
                  style={{
                    cursor: 'pointer',
                    height: 40
                  }}
                />

                <IconButton
                  ref={anchorRef}
                  aria-label="account of current user"
                  aria-controls={openMenu ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={submitLogout}
                  sx={{
                    cursorPointer: 'none',
                    fontWeight: 500,
                    color: '#344054',
                    fontSize: '16px',
                  }}>
                  Logout
                </IconButton>
              </Box>
            )}
          </Toolbar>
          {(location.pathname == '/user/dashboard' || location.pathname == '/orgman/dashboard/') ? (
            <Toolbar
              sx={{
                background: '#63A7AA',
                fontstyle: 'normal',
                fontWeight: 300,
                fontSize: '22px',
                lineHeight: '38px',
                textTransform: 'uppercase',
              }}>
              Dashboard
            </Toolbar>
          ) : null}
        </AppBar>
        {props.isLoggedIn && (
          <Drawer
            jsonTabs={userTabs}
            adminTabs={adminTabs}
            orgmanTabs={orgmanTabs}
            drawerOpen={drawerOpen}
            handleDrawerClose={handleDrawerClose}
            globalActions={props.globalActions}
          />
        )}
        <Box
          component="main"
          sx={{
            flexDirection: 'column',
            width: getMainLayoutWidth(drawerOpen, isMobile, props.currentPage),
          }}>

          <div className={classes.toolbar} />
          {!props.checkingCookie &&
            (props.isLoggedIn ? (
              <AuthContainer>
                {isTimeclock ? (
                  <Switch>
                    <Route path="/timeclock" component={TimeClockPage} />
                    <Route path="*">
                      <Redirect to="/timeclock" />
                    </Route>
                  </Switch>
                ) : (
                  <Switch>
                    {userTabs.length > 0 && getAllRoutes(userTabs)}
                    {adminTabs.length > 0 && getAllRoutes(adminTabs)}
                    {orgmanTabs.length > 0 && getAllRoutes(orgmanTabs)}
                    {(userTabs.length > 0 || adminTabs.length > 0 || orgmanTabs.length > 0) && (
                      <Route path={'*'}>
                        <Redirect to={'/user/dashboard'} />
                      </Route>
                    )}
                    {isOrgman && <Redirect to={`/orgman/dashboard/`} />}
                  </Switch>
                )}
              </AuthContainer>
            ) : (
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/reset_password" component={ResetPasswordContainer} />
                <Route path="/reset" component={ResetContainer} />
                <Route path="/form" component={FormContainer} />
                {isResetPassword && <Redirect to={`/reset_password${location.search}`} />}
                {isSSO && <Redirect to={`/login${location.search}`} />}
                {isSSOWithUserToken && <Redirect to={`/login${location.search}`} />}
                <Route path="*">
                  <Redirect to={'/login'} />
                </Route>
              </Switch>
            ))}
        </Box>
      </Box>
    </>
  );
};

export default withRouter(Header);

function getMainLayoutWidth(isDrawerOpen, isMobile, currentPage) {
  if (currentPage === '/login' || isMobile) {
    return '100%';
  }
  return `calc(100% - ${isDrawerOpen ? 240 : 65}px)`;
}
