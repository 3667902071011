import axios from 'axios';
import APIManager from './APIManager';
import qs from 'qs'; //started using qs because querystring doesn't support nested objects
import { get, post, put, del } from './REST';
import concatURL from '../helpers/concatURL';
import { useLocation } from 'react-router';

/*

API.js: houses functions for discrete, one-off API calls, as opposed to GlobalActions,
which does the API calls which are relevant for Redux / global state

*/

//TODO: organize all the functions by feature, similar to postman collection, then export from index.js file
//TODO: make all functions return `get(params, url, etc...`

export function fetchShiftEditConfig(params) {
  return get(params, '/loader/edit_shift', 'Fetch shift edit config');
}

export function fetchShiftCreateConfig(params) {
  return get(params, '/loader/create_shift', 'Fetch shift create config');
}

export function fetchCreateCalloutConfig(params) {
  return get(params, '/loader/callout', 'Fetch create callout config', {
    validateStatus: false,
  });
}

export function shiftCheck(params) {
  return get(params, '/shift/check', 'Check if valid shift', {
    validateStatus: false,
  });
}

export function createShift(params) {
  return post(params, '/schedule/shift', 'Create a shift');
}

export function getAllUrlParams(url) {
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
  let obj = {};

  if (queryString) {
    queryString = queryString.split('#')[0];
    let arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      let a = arr[i].split('=');
      let paramName = a[0];
      let paramValue = typeof a[1] === 'undefined' ? true : a[1];

      if (paramName.match(/\[(\d+)?\]$/)) {
        let key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        if (paramName.match(/\[\d+\]$/)) {
          let index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          obj[key].push(paramValue);
        }
      } else {
        if (!obj[paramName]) {
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

export function putShift(form) {
  // let url = `${process.env.API_URL}/schedule/shift`; //TODO
  //required body
  let body = {
    duty_id: form.dutyID,
    roster_id: form.rosterID,
    dutydate: form.day,
  };
  //optional body
  form.shiftID && (body.shift_id = form.shiftID);
  form.shiftStart && (body.timein = form.shiftStart);
  form.shiftEnd && (body.timeout = form.shiftEnd);
  form.shiftNotes && (body.notes = form.shiftNotes);
  if (form.hideShift) {
    body.hidden = '1';
  } else {
    body.hidden = '0';
  }
  form.shiftCategory && (body.cat_id = form.shiftCategory);
  form.preset_timeclock_cat_id && (body.preset_timeclock_cat_id = form.preset_timeclock_cat_id);
  form.shift_bid_id && (body.shift_bid_id = form.shift_bid_id);
  form.list_id && (body.list_id = form.list_id);
  form.event_id && (body.event_id = form.event_id);
  form.position_id && (body.position_id = form.position_id);
  form.apparatus_id && (body.apparatus_id = form.apparatus_id);
  form.child_timein && (body.child_timein = form.child_timein);
  form.child_timeout && (body.child_timeout = form.child_timeout);

  return put(body, '/schedule/shift', 'Put shift', { validateStatus: true });
}

export function deleteShift(params) {
  // params.test = 1;
  return del(params, '/schedule/shift', 'Delete shift');
}

export function fetchReportsConfig() {
  return get({}, '/loader/reports', 'Fetch reports config');
}

export function fetchReport(params) {
  return get(params, '/reports/report', 'Fetch a report');
}

export function fetchRosterList(params) {
  params.type = 'signup';
  return get(params, '/roster/list/type/full', 'Fetch roster list');
}

export function fetchManagerConfig(params) {
  return get(params, '/loader/manager', 'Fetch manager config');
}

export function fetchCalendarConfig() {
  //TODO: sus out correct url
  return get({}, '/loader/calendar?seldate=1488571440&scope=month', 'Fetch calendar config');
}

export function fetchDataCalendar(action, endpoint, params = {}) {
  return get(params, endpoint, action);
}

export function fetchMyShifts() {
  //TODO: params
  let params = {
    start: '2017-02-01',
    limit: '10',
    roster_id: 'me',
  };

  return get(params, '/schedule/shifts', 'Fetch my shifts');
}

export function createEvent(params) {
  //TODO: format body in code that calls createEvent
  return post(params, '/calendar/dayitem', 'Create a new non signup event');
}

//edits an existing event. MUST provide dayitem_id in params
export function editEvent(params) {
  return put(params, '/calendar/dayitem', 'Create a new non signup event');
}

//deletes an existing event. MUST provide dayitem_id in params
export function deleteEvent(params) {
  return del(params, '/calendar/dayitem', 'Create a new non signup event');
}

export function createExchangeRequest(params) {
  return post(params, '/schedule/exchange_request', 'Create exchange request', { validateStatus: true });
}

export function createExchangeDirect(params) {
  return post(params, '/schedule/direct_exchange', 'Create direct exchange request');
}

export function fetchExchangesAvailableStaff(params) {
  let body = {
    exchange_start: params.start,
    exchange_end: params.start,
    shift_id: params.shiftID,
  };
  return get(body, '/exchange/available_staff', 'Fetch available staff for exchanges');
}

export function fetchExchangesAvailableShifts(params) {
  let body = {
    exchange_start: params.start,
    exchange_end: params.start,
    offer_roster_id: params.rosterID,
    shift_id: params.shiftID,
  };
  return get(body, '/exchange/available_shifts', 'Fetch available shifts for an exchange');
}

export function dynamicOEButtonCall(url, method, params) {
  // use the built in get method if we are doing a GET request, since the data
  // formatting is different
  if (method === 'GET') {
    return get(params, url, 'button GET request')
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err.response;
      });
  } else {
    return APIManager.getKey()
      .then(key => {
        let headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
        };

        if (key) {
          headers['X-API-KEY'] = key;
        }

        return axios.request({
          url: concatURL(url),
          method,
          headers,
          data: qs.stringify(params),
        });
      })
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err.response;
      });
  }
}

//simple pull of content from predefined endpoint
// add a slash just in case it was formatted wrong from the API. an extra doesn't hurt
export function getContentFromEndpoint(params, endpoint, method = 'GET') {
  if (method === 'GET') {
    return get(params, '/' + endpoint, 'getting content from endpoint');
  } else if (method === 'POST') {
    return post(params, '/' + endpoint, 'posting content to endpoint');
  }
}

export function fetchExchangeRequestConfig(params) {
  return get(params, '/schedule/exchange_request', 'Fetch exchange request details for a single exchange');
}

export function getAdminTabs(params) {
  return get(params, '/admin/adminpage', 'Fetch admin tabs');
}

export function getTimeClock(params) {
  return get(params, '/timeclock/loader', 'Fetch time clock');
}

export function getOrgMan(params) {
 if (window.location.pathname.includes('orgman') ) { return get(params, '/orgman/loader', 'Fetch Orgman'); }
}

export function getUserTabs(params) {
  return get(params, '/loader/userpage2', 'Fetch user tabs');
}
export function getLogoHeader(params) {
  return get(params, '/loader/header', 'Fetch logo');
}

export function getReport(params) {
  return get(params, '/reports/report', 'Fetch report results');
}

export function createDirectExchangeOffer(params) {
  return post(params, '/schedule/direct_exchange', 'Create direct exchange offer', { validateStatus: false });
}

export function createExchangeOffer(params) {
  return post(params, '/schedule/exchange_offer', 'Create exchange offer', {
    validateStatus: false,
  });
}

export function approveExchangeRequest(params) {
  return put(params, '/exchange/approve', 'Approve an exchange request offer', { validateStatus: false });
}

export function denyExchangeRequest(params) {
  return put(params, '/exchange/deny', 'Deny an exchange request offer');
}

export function deleteExchangeRequest(params) {
  return del(params, '/schedule/exchange_request', 'Delete an exchange request');
}

export function deleteExchangeRequestOffer(params) {
  return del(params, '/schedule/exchange_offer', 'Delete an exchange request offer');
}

export function splitShiftByForm(params) {
  return post(params, '/shift/split', 'Split shift by form');
}
export function fetchMyAccountValues() {
  return get({}, '/settings/myaccount_values', 'Get my account values');
}
