import React from 'react';
import moment from 'moment';
import Loading from 'react-loading';
import Button from '@mui/material/Button';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dynamicOEButtonCall } from '../../actions/API';
import { get } from '../../actions/REST';
import Form from './Form';
import DialogBox from './DialogBox';
import Buttonbar from './Buttonbar';
import Records from './Records';
import DayPanel from '../Calendar/DayPanel';
import CustomToolTip from './CustomToolTip';
import APIResponseDialog from './APIResponseDialog';
import getSizeInPixel, { HEIGHTS } from '../../constants/boxSizes';
import classNames from 'classnames';

const buttonTypes = require('../../constants/buttonTypes.js');
const boxTypes = require('../../constants/boxTypes.js');

export default class ContentBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: this.props.collapsed || false,
      header: null,
      body: null,
      dialog_box: {
        show: false,
        data: null,
        uniqueID: '',
      },
      dialog: {
        open: false,
        response: null,
      },
      loading: false,
    };
    this.parseData = this.parseData.bind(this);
    this.updateContentBox = this.updateContentBox.bind(this);
  }

  componentDidMount() {
    this.props.data && this.parseData(this.props.data);
  }

  componentDidUpdate(prevProps) {
    this.props !== prevProps &&
      this.props.data &&
      this.props.data !== prevProps.data &&
      this.parseData(this.props.data);
  }

  parseData(data) {
    if (!data.configs) {
      console.warn("can't render contentBox without configs");
      return null;
    }
    const { configs } = data;
    const header = {
      id: configs.id,
      label: configs.label,
      class: configs.class,
      navigation: configs.navigation,
      seldate: configs.seldate,
      scope: configs.scope,
      icon: configs.icon,
      iconClass: configs.iconClass,
    };
    const body = {
      data: data.data,
      type: configs.box_type,
      top_buttonbar: configs.buttonbar,
      open_link: configs.open_link,
      maxHeight: configs.maxHeight,
    };
    this.setState({
      header,
      body,
    });
  }

  updateContentBox(params = {}, endpoint) {
    get(params, `/${endpoint}`, 'Updating Content Box').then(
      response => response.data && response.data.data && this.parseData(response.data.data),
    );
  }

  /*
   ** Toggles the state of collapsed that will cause the body
   ** of the content-box to show your hide.
   */
  toggleCollapse() {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  }

  /*
   ** param {object} - response
   */
  openAPIDialog(response) {
    // just bump it open then immediately close on this end, to leave timing
    // up to the dialog component
    this.setState(
      {
        loading: false,
        dialog: {
          open: true,
          response: response,
        },
      },
      () => {
        setTimeout(() => {
          this?.setState({ dialog: { open: false, response: response } });
        }, 3000);
      },
    );
  }

  //Close the DialogBox and delete the data from the state
  closeDialogBox() {
    this.setState(
      {
        dialog_box: {
          show: false,
          data: null,
        },
      },
      () => this.props.idleTimer && this.props.idleTimer.resume(),
    );
  }

  /*
   ** Open the DialogBox passing the button clicked as the data
   **
   ** param {Object} - data
   ** param {String} - dialog_type
   */
  openDialogBox(data, dialog_type, uniqueID, mousePos) {
    this.setState(
      {
        loading: false,
        dialog_box: {
          uniqueID,
          data: mousePos ? Object.assign({}, data, { mousePos }) : data,
          show: true,
          dialog_type,
        },
      },
      () => this.props.idleTimer && this.props.idleTimer.pause(),
    );
  }

  getDialogBoxData(button, uniqueID, mousePos) {
    this.setState({ loading: true });
    //If we have an endpoint we load the DialogBox from that endpoint
    if (button.endpoint) {
      get(button.variables, `/${button.endpoint}`, 'Fetching data for DialogBox').then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (
            response &&
            response.data &&
            (response.data.config || (response.data.data && response.data.data.config))
          ) {
            this.openDialogBox(response.data, button.dialog_type, uniqueID, mousePos);
          } else {
            /* If it's a successful response (200 - 299) but the format is not correct, must likely is a
             ** back-end error. So we clear the LOADING state and we call the APIDialog.*/
            this.openAPIDialog({
              //faking the correct response when there's an Error in the API
              data: {
                errmsg: response.data,
              },
            });
          }
        } else {
          //If it wasn't a successful response then let's open the APIDialog
          this.setState({ loading: false }, () => this.openAPIDialog(response));
        }
      });
    } else {
      //If no endpoint, then it means the DialogBox is inline
      this.openDialogBox(button, button.dialog_type, uniqueID, mousePos);
    }
  }

  handleButton(button, uniqueID, mousePos) {
    if (button.action === buttonTypes.DIALOG_BOX) {
      return this.getDialogBoxData(button, uniqueID, mousePos);
    } else if (button.endpoint && button.method) {
      const variables = button.variables || {};
      dynamicOEButtonCall(button.endpoint, button.method, variables).then(response => {
        if (response.status >= 200 && response.status < 300) {
          this.props.refreshPage();
        }
        if (button.action === '_blank') {
          window.open().document.write(response.data.data ? response.data.data : response.data);
        } else {
          this.openAPIDialog(response);
        }
      });
    } else {
      console.warn('please include endpoint and method');
    }
  }

  /*
   ** param {String} - label
   ** param {Object} - style
   ** param {func} - onClick
   */
  getHeaderLink(label, style = {}, icon_location = null, onClick) {
    return (
      <div className="nav-calendar link" style={style} onClick={() => onClick()}>
        {icon_location && icon_location === 'LEFT' && <FontAwesomeIcon icon="angle-left" size="lg" />}
        <span> {label} </span>
        {icon_location && icon_location === 'RIGHT' && <FontAwesomeIcon icon="angle-right" size="lg" />}
      </div>
    );
  }

  renderNavigationHeader(header) {
    const { next } = header.navigation;
    const { last } = header.navigation;
    const next_params = {
      seldate: next.seldate,
      scope: header.scope,
    };
    const last_params = {
      seldate: last.seldate,
      scope: header.scope,
    };

    const icon = this.state.collapsed ? 'angle-down' : 'angle-up';
    if (header.seldate) {
      //This means it's mini-Calendar
      return (
        <div key="box_header" className={classNames('content-box-header', header.class)} style={{ display: 'flex' }}>
          {this.getHeaderLink(last.label, {}, null, () => this.updateContentBox(last_params, last.endpoint))}
          <div
            className="content-box-title link"
            onClick={() => this.props.onChangeToMDay(header.seldate)}
            style={{ width: '45%', textAlign: 'center' }}
          >
            <span>{header.label}</span>
          </div>
          {this.getHeaderLink(next.label, { textAlign: 'right' }, null, () =>
            this.updateContentBox(next_params, next.endpoint),
          )}
          <div style={{ marginLeft: '10px' }} className="expandable-button" onClick={this.toggleCollapse.bind(this)}>
            <FontAwesomeIcon icon={icon} size="lg" />
          </div>
        </div>
      );
    } else {
      //And the other Navigation Header is DutyRoster in DayView
      return (
        <div key="box_header" className={classNames('content-box-header', header.class)} style={{ display: 'flex' }}>
          {this.getHeaderLink(last.label, {}, 'LEFT', () => this.props.refreshPage(last.endpoint, last_params))}
          <div className="content-box-title" style={{ width: '50%', textAlign: 'center' }}>
            <span>{header.label}</span>
          </div>
          {this.getHeaderLink(next.label, { textAlign: 'right', marginRight: '5px' }, 'RIGHT', () =>
            this.props.refreshPage(next.endpoint, next_params),
          )}
          <div style={{ marginLeft: '10px' }} className="expandable-button" onClick={this.toggleCollapse.bind(this)}>
            <FontAwesomeIcon icon={icon} size="lg" />
          </div>
        </div>
      );
    }
  }

  getCollapsableHeader(header, body = null) {
    const icon = this.state.collapsed ? 'angle-down' : 'angle-up';
    return (
      <>
        {this.props.columnGetFrom === 'DashboardCoulmn' ? (
          <div className={classNames('content-header-wrapper border')}>
            <h4>{header.label}</h4>
            {header.label === 'Time Off' || header.label === 'Time Clock' ? null : (
              <h4 className={body?.data?.class}dangerouslySetInnerHTML={{ __html: body?.data?.details }}></h4>
            )}
          </div>
        ) : (
          <div key="box_header" className={classNames('content-box-header ', header.class)}>
            <div className="header-icon">
              <CustomToolTip icon={header.icon} iconClass={header.iconClass} />
            </div>
            <div className="content-box-title">
              <span>{header.label}</span>
            </div>
            <div className="expandable-button" onClick={this.toggleCollapse.bind(this)}>
              <FontAwesomeIcon icon={icon} size="lg" />
            </div>
          </div>
        )}
      </>
    );
  }

  /*
   ** param {React Element} - rendered_data
   ** param {Array} - top_buttonbar
   ** param {String} - innerClass
   ** param {String} - outerClass
   */
  renderBody(rendered_data, top_buttonbar, innerClass = '', outerClass = '') {
    const displayClass = this.state.collapsed ? ' display-none' : '';
    let rendered_buttonbar = null;
    const buttonbar_style = { display:"block", margin: '0 10px 0 auto' };
    if (top_buttonbar) {
      rendered_buttonbar = (
        <Buttonbar
          style={buttonbar_style}
          ndClass={'top-buttonbar'}
          buttonbar={top_buttonbar}
          handleButton={this.handleButton.bind(this)}
        />
      );
    }

    // TODO: See ./src/constants/boxSize.js
    let bodyStyle = {};
    let maxHeight = getSizeInPixel(this.state.body.maxHeight);
    /* If there's a maxHeight then we set the bodyStyle with the maxHeight and
     ** we display vertical Scrollbars when the body overflows, but don't display the
     ** horizontal scrollbar.*/
    if (maxHeight && !isMobile) {
      bodyStyle = {
        maxHeight,
        overflowY: 'auto',
        overflowX: 'hidden',
      };
    }

    if (this.state.body.maxHeight === HEIGHTS.LARGE) {
      bodyStyle.minHeight = '570px';
    }

    return (
      <div key="box_body" className={classNames(outerClass, 'content-box-body', displayClass)} style={bodyStyle}>
        {rendered_buttonbar}
        <div className={innerClass}>{rendered_data}</div>
      </div>
    );
  }

  renderNavCalendar(data, top_buttonbar) {
    let rendered_calendar = [];
    const header_days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const width = { width: `${100 / 7}%` };
    /* create Calendar header */
    for (let header_day of header_days) {
      rendered_calendar.push(
        <abbr key={header_day} title={header_day} className="small-calendar-day" style={width}>
          <strong>{header_day.slice(0, 2)}</strong>
        </abbr>,
      );
    }

    const keys = Object.keys(data);
    /* Push empty days to position days accordingly */
    for (let empty_day = 0; empty_day < moment(keys[0]).weekday(); empty_day++) {
      rendered_calendar.push(<span key={'emp_' + empty_day} className="small-calendar-day" style={width} />);
    }

    /* looping through the days, comparing to the dates from the back-end */
    for (let date of keys) {
      let day_data = data[date];
      let extra_class = day_data ? day_data.class : '';
      rendered_calendar.push(
        <abbr
          key={date}
          onClick={() => this.props.refreshPage(day_data.endpoint)}
          style={width}
          className={`small-calendar-day ${extra_class}`}
          title={day_data ? day_data.hovertext : ''}
        >
          {parseInt(date.split('-').pop())}
        </abbr>,
      );
    }
    return this.renderBody(rendered_calendar, top_buttonbar, 'mini-calendar', null);
  }

  renderDutyRoster(data, top_buttonbar, open_link, body_label, columnGetFrom) {
    let rendered_quick_link = null;
    if (open_link) {
      rendered_quick_link = (
        <div className="quick-link-wrapper" onClick={() => this.props.handleQuickLink(open_link)}>
          <div className="quick-link">
            <span>Open</span>
          </div>
        </div>
      );
    }
    const day_panel = (
      <div>
        {rendered_quick_link}
        <DayPanel
          mday_type="dutyroster"
          dutys_black_list={this.props.dutys_black_list}
          stations_black_list={this.props.stations_black_list}
          data={data}
          limit_to={100}
          onSuccess={this.props.refreshPage}
          body_label={this.props.body_label}
          columnGetFrom={columnGetFrom}
        />
      </div>
    );
    return this.renderBody(day_panel, top_buttonbar, 'no-border', null);
  }

  renderRecords(data, top_buttonbar, columnGetFrom, body_label) {
    let rendered_records = null;
    /* Records are supposed to be an array */
    if (Array.isArray(data) && data.length > 0) {
      rendered_records = (
        <Records
          records={data}
          handleButton={this.handleButton.bind(this)}
          columnGetFrom={columnGetFrom}
          body_label={body_label}
        />
      );
    }
    return this.renderBody(rendered_records, top_buttonbar, '', 'no-horizontal-padding');
  }

  renderForm(data, top_buttonbar) {
    const form = (
      <Form
        key={'form' + data.form.id + new Date().getTime()}
        formData={data.form}
        standAlone={false}
        miniForm={data.form.display === 'mini-form' ? true : false}
        success={() => this.props.refreshPage()}
      />
    );
    return this.renderBody(form, top_buttonbar, '', 'noscroll-y no-horizontal-padding');
  }

  renderCombo(data, top_buttonbar) {
    let rendered_combo = null;
    if (data && data.rows) {
      rendered_combo = data.rows.map(row => (
        <div className="single-row" key={'row_' + row.configs.id}>
          {this.getBody(row, row.configs.type)}
        </div>
      ));
    }
    return this.renderBody(rendered_combo, top_buttonbar, null, null);
  }

  renderHTML(data, top_buttonbar) {
    const rendered_html = <div key="html_body" dangerouslySetInnerHTML={{ __html: data }} />;
    return this.renderBody(rendered_html, top_buttonbar, null, null);
  }

  renderVButtonbar(data, top_buttonbar) {
    const rendered_buttons = data.map(button => (
      <Button
        key={button.id}
        onClick={() => this.handleButton(button)}
        size="large"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mb: 1 }}
      >
        {button.label}
      </Button>
    ));
    return this.renderBody(rendered_buttons, top_buttonbar, 'vertical-buttons', null);
  }

  renderButtonbar(data, top_buttonbar) {
    const rendered_buttonbar = (
      <Buttonbar
        buttonbar={data.lbuttonbar || data.buttonbar}
        handleButton={this.handleButton.bind(this)}
        ndClass={data.lbuttonbar ? 'align-left' : 'align-right'}
      />
    );
    return this.renderBody(rendered_buttonbar, top_buttonbar, null, null);
  }

  renderDialogBox() {
    const { dialog_box } = this.state;
    return (
      <DialogBox
        key={'dialog_box' + dialog_box.uniqueID}
        data={dialog_box.data}
        dialog_type={dialog_box.dialog_type}
        onClose={this.closeDialogBox.bind(this)}
        onSuccess={this.props.refreshPage}
        openDialog={this.openAPIDialog.bind(this)}
      />
    );
  }

  /*
   ** Creates the Body of the ContentBox, passing top_buttonbar to each
   ** render function. This is primarly done because of renderCombo, since
   ** it reuses this function to combine multiple body Types into a single
   ** body, so by doing this we limit the top_buttonbar to only be rendered
   ** by the "Main Body" (that will be the only one with top_buttonbar in the
   ** body.
   **
   ** If you have another type of box, create a separate
   ** function, add another property to boxTypes.js in src/constants/ and add
   ** the other case.
   **
   ** param {Object} - body
   ** param {String} - type
   */
  getBody(body, type, body_label = '', columnGetFrom) {
    switch (type) {
      case boxTypes.RECORDS:
        return this.renderRecords(body.data, body.top_buttonbar, columnGetFrom, body_label);
      case boxTypes.FORM:
        return this.renderForm(body.data, body.top_buttonbar);
      case boxTypes.HTML:
        return this.renderHTML(body.data, body.top_buttonbar);
      case boxTypes.COMBO:
        return this.renderCombo(body.data, body.top_buttonbar);
      case boxTypes.BUTTONBAR:
        return this.renderButtonbar(body.data, body.top_buttonbar);
      case boxTypes.NAVCALENDAR:
        return this.renderNavCalendar(body.data, body.top_buttonbar);
      case boxTypes.VBUTTONBAR:
        return this.renderVButtonbar(body.data, body.top_buttonbar);
      case boxTypes.DUTYROSTER:
        return this.renderDutyRoster(
          body.data,
          body.top_buttonbar,
          body.open_link,
          body_label,
          this.props.columnGetFrom,
        );
      default:
        console.warn('box type not supported');
        return null;
    }
  }

  /*
   ** param {object} - header
   ** param {object} - body
   */
  getBox(header, body, columnGetFrom) {
    const rendered_body = this.getBody(body, body.type, header?.label, columnGetFrom);
    const rendered_header = header.navigation
      ? this.renderNavigationHeader(header)
      : this.getCollapsableHeader(header, body);
    return [rendered_header, rendered_body];
  }

  render() {
    if (this.state.body && this.state.body.type) {
      const { header, body } = this.state;
      return (
        <div className="content-box dashboard--content-box" style={this.props.style}>
          {this.state.loading && (
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
              <Loading type="spinningBubbles" color="#207cca" height="100px" width="100px" />
            </div>
          )}
          {this.state.dialog_box.show && this.state.dialog_box.data && this.renderDialogBox()}
          {this.getBox(header, body, this.props.columnGetFrom)}
          <APIResponseDialog open={this.state.dialog.open} response={this.state.dialog.response} notify={true} />
        </div>
      );
    } else {
      return null;
    }
  }
}
