import React from 'react';
import Loading from 'react-loading';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from '../../actions/REST';
import ContentBox from '../Common/ContentBox';
import CustomToolTip from '../Common/CustomToolTip';
import APIResponseDialog from '../Common/APIResponseDialog';
import { dynamicOEButtonCall, getAllUrlParams } from '../../actions/API';
import Router from '../AppLayout/Router';
import classNames from 'classnames';

const box_type = require('../../constants/boxTypes.js');
const filterTypes = {
  DUTY: 'duty',
  STATION: 'station',
};

export default class DayView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      displayRouter: false,
      routerData: null,
      show_filters: false,
      duty_filters: null,
      dutys_black_list: [],
      station_filters: null,
      stations_black_list: [],
      clear_filter_btn: false,
      clear_filter_btn_label: '',
      filters_hidden: false,
      quickLink: {},
      filters_api: {
        endpoint: null,
        method: null,
        variables: null,
      },
      dayview_endpoint: null,
      date: null,
      dialog_box: {
        data: null,
        show: false,
      },
      dialog: {
        open: false,
        response: null,
      },
    };
    this.handleResponse = this.handleResponse.bind(this);
    this.fetchDayView = this.fetchDayView.bind(this);
    /* We debounce the saveFilters method to control the amount of Requests
     ** we make to the Back-end when we are changing the filters.
     **
     ** If we change a filter state (check or uncheck), we call saveFilters
     ** and because it's debounced it will wait 3 seconds to see if we call
     ** saveFilters again (by changing another filter), then it resets the
     ** counter and the 3 seconds countdown starts again. If the 3 seconds
     ** expire then we finally run saveFilters function.
     **
     ** Enhancing the performance of this Component.
     */
    this.saveFilters = this.debounce(this.saveFilters, 3000);
  }

  debounce(func, delay) {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  }

  componentDidMount() {
    if (!this.props.data && this.props.endpoint) {
      return this.fetchDayView(this.props.endpoint);
    } else if (this.props.data) {
      const { data } = this.props.data;
      if (data) {
        return this.parseData(data.data, this.props.endpoint, {}, data.config);
      }
    }
    console.warn('endpoint not found');
    return null;
  }

  componentDidUpdate(prevProps) {
    /* ** When Calendar passes open_link it means they clicked the Open link in Dashboard's dutyroster.
     ** At this point if the endpoint passed from Calendar is different than the current endpoint then
     ** we decide to fetch with the endpoint that was passed from Calendar (Today's endpoint). */
    if (
      this.props.endpoint !== prevProps.endpoint &&
      this.state.dayview_endpoint &&
      this.props.open_link &&
      this.props.endpoint !== this.state.dayview_endpoint
    ) {
      this.fetchDayView(this.props.endpoint);
    }
  }

  encodeParams(params) {
    if (!params) {
      return null;
    }
    let ret = [];
    for (let d in params) {
      params[d] && ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(params[d]));
    }
    return ret.join('&');
  }

  parseData(data, endpoint, params, config) {
    let dayview_endpoint = endpoint;
    let encoded_params = this.encodeParams(params);
    let filters_config = null;
    let duty_filters = [];
    let station_filters = [];
    let dutys_black_list = [];
    let stations_black_list = [];
    let filters_hidden = false;

    if (encoded_params) {
      dayview_endpoint += '?' + encoded_params;
    }
    if (data && data.cols && data.cols.length > 0) {
      data.cols.map(
        col =>
          col.containers &&
          col.containers.map(
            container =>
              container.boxes &&
              container.boxes.map(box => {
                if (box && box.configs && box.configs.box_type === box_type.DUTYROSTER && box.data) {
                  filters_config = box.data.filters;
                  if (filters_config && filters_config.duty) {
                    duty_filters = Object.keys(filters_config.duty).map(key => filters_config.duty[key]);
                  }
                  if (filters_config && filters_config.station) {
                    station_filters = Object.keys(filters_config.station).map(key => filters_config.station[key]);
                  }
                }
                return box;
              }),
          ),
      );
    }
    //Creating Black Lists
    if (duty_filters.length > 0) {
      duty_filters
        .filter(filter => !filter.selected)
        .map(filter => {
          if (filter.controlled && filter.controlled.length > 0) {
            for (let id of filter.controlled) {
              dutys_black_list.push(id);
            }
          }
          return filter;
        });
    }
    if (station_filters.length > 0) {
      station_filters
        .filter(filter => !filter.selected)
        .map(filter => {
          if (filter.controlled && filter.controlled.length > 0) {
            for (let id of filter.controlled) {
              stations_black_list.push(id);
            }
          }
          return filter;
        });
    }
    let filters_api = {
      endpoint: null,
      method: null,
      variables: null,
    };
    let clear_filter_btn = false; //Default
    let clear_filter_btn_label; //Default
    if (filters_config) {
      filters_hidden = filters_config.hidden;
      if (filters_config.clear_btn) {
        //Allow the back-end to show the clear all button
        clear_filter_btn = filters_config.clear_btn;
      }
      clear_filter_btn_label = filters_config.clear_btn_label || 'Show All';
      if (filters_config.duty) {
        duty_filters = Object.keys(filters_config.duty).map(key => filters_config.duty[key]);
      }
      if (filters_config.station) {
        station_filters = Object.keys(filters_config.station).map(key => filters_config.station[key]);
      }
      filters_api['endpoint'] = filters_config.endpoint;
      filters_api['method'] = filters_config.method;
      filters_api['variables'] = filters_config.variables;
    }
    this.setState({
      columns: data.cols,
      date: params.seldate,
      duty_filters,
      station_filters,
      dutys_black_list,
      stations_black_list,
      filters_api,
      filters_hidden,
      clear_filter_btn,
      clear_filter_btn_label,
      dayview_endpoint,
      quickLink: {
        routeTo: config ? `/${config.quick_link}` : null,
        label: config ? config.route_label : null,
      },
    });
  }

  openAPIDialog(response) {
    // just bump it open then immediately close on this end, to leave timing
    // up to the dialog component
    this.setState({ dialog: { open: true, response: response } }, () => {
      setTimeout(() => {
        this?.setState({ dialog: { open: false, response: response } });
      }, 3000);
    });
  }

  //Close the box and delete the data from the state
  closeDialogBox() {
    this.setState({
      dialog_box: {
        data: null,
        show: false,
      },
    });
  }

  handleResponse(response, endpoint, params) {
    //validating JSON response
    if (response.data && response.data.data && response.data.data.cols && response.data.data.cols.length > 0) {
      return this.parseData(response.data.data, endpoint, params, response.data.config);
    }
    return null;
  }

  fetchDayView(endpoint = this.state.dayview_endpoint, params = {}) {
    const verified_endpoint = endpoint.charAt(0) === '/' ? endpoint : `/${endpoint}`;
    get(params, verified_endpoint, 'Fetching Day View').then(response => {
      if (response && response.data && response.data.ribbon) {
        const paramsFromURL = getAllUrlParams(verified_endpoint);
        let variables = {};
        if (paramsFromURL && paramsFromURL.seldate) {
          variables['seldate'] = paramsFromURL.seldate;
          variables['scope'] = 'day';
        } else if (params && params.seldate) {
          variables['seldate'] = params.seldate;
          variables['scope'] = 'day';
        }
        this.props.updateRibbon(
          {
            variables,
            ...response.data.ribbon,
          },
          true,
        );
      }
      this.handleResponse(response, verified_endpoint, params);
    });
  }

  /*
   ** Here we save the filters in the API.
   **
   ** Note: This function in debounced in the constructor, meaning that this
   ** function will wait 3 seconds after being called, if it gets called again
   ** before the 3 seconds it resets the timer and wait again, If it doesn't get
   ** called in 3 seconds then it's finally executed.
   */
  saveFilters() {
    if (this.state.filters_api.endpoint) {
      let variables = {};
      if (this.state.station_filters) {
        //Add Station Filters
        for (let filter of this.state.station_filters) {
          Object.assign(variables, {
            [filter.name]: filter.selected,
          });
        }
      }
      if (this.state.duty_filters) {
        //Add Duty Filters
        for (let filter of this.state.duty_filters) {
          Object.assign(variables, {
            [filter.name]: filter.selected,
          });
        }
      }
      //Add variables sent from the Back-end
      if (Object.keys(this.state.filters_api.variables).length > 0) {
        Object.assign(variables, this.state.filters_api.variables);
      }
      const { endpoint, method } = this.state.filters_api;
      dynamicOEButtonCall(`/${endpoint}`, method, variables).then(response => {
        this.openAPIDialog(response);
      });
    } else {
      console.warn('no endpoint was found');
    }
  }

  /* Change all the filters to be checked and then save in the back-end. */
  clearAllFilters() {
    this.setState(
      prevState => ({
        dutys_black_list: [],
        stations_black_list: [],
        duty_filters: prevState.duty_filters.map(filter => Object.assign(filter, { selected: true })),
        station_filters: prevState.station_filters.map(filter => Object.assign(filter, { selected: true })),
      }),
      () => this.saveFilters(),
    );
  }

  /*
   ** Add the Array of ids to the black_list so it can
   ** be hidden and change the selected of the Filter that was
   ** clicked.
   **
   ** @param {Array} - ids
   ** @param {Object} - newFilter
   ** @param {String} - filter_type
   */
  addToBlackList(ids, newFilter, filter_type) {
    this.setState(
      prevState => {
        newFilter['selected'] = !newFilter.selected;
        switch (filter_type) {
          case filterTypes.DUTY:
            return {
              dutys_black_list: prevState.dutys_black_list.concat(ids),
              duty_filters: prevState.duty_filters.map(prevFilter =>
                prevFilter.name === newFilter.name ? newFilter : prevFilter,
              ),
            };
          case filterTypes.STATION:
            return {
              stations_black_list: prevState.stations_black_list.concat(ids),
              station_filters: prevState.station_filters.map(prevFilter =>
                prevFilter.name === newFilter.name ? newFilter : prevFilter,
              ),
            };
          default:
            return {};
        }
      },
      () => this.saveFilters(),
    );
  }

  /*
   ** Remove the Array of ids from the black_list so it can
   ** be displayed on the screen and change the selected of the
   ** Filter that was clicked.
   **
   ** @param {Array} - ids
   ** @param {Object} - newFilter
   ** @param {String} - filter_type
   */
  removeFromBlackList(ids, newFilter, filter_type) {
    this.setState(
      prevState => {
        newFilter['selected'] = !newFilter.selected;
        switch (filter_type) {
          case filterTypes.DUTY:
            return {
              duty_filters: prevState.duty_filters.map(prevFilter =>
                prevFilter.name === newFilter.name ? newFilter : prevFilter,
              ),
              dutys_black_list: ids
                ? prevState.dutys_black_list.filter(id => !ids.includes(id))
                : prevState.dutys_black_list,
            };
          case filterTypes.STATION:
            return {
              station_filters: prevState.station_filters.map(prevFilter =>
                prevFilter.name === newFilter.name ? newFilter : prevFilter,
              ),
              stations_black_list: ids
                ? prevState.stations_black_list.filter(id => !ids.includes(id))
                : prevState.stations_black_list,
            };
          default:
            return {};
        }
      },
      () => this.saveFilters(),
    );
  }

  handleFilter(filter_clicked, filter_type) {
    let controlled = filter_clicked.controlled;
    if (filter_clicked.selected) {
      this.addToBlackList(controlled, filter_clicked, filter_type);
    } else {
      this.removeFromBlackList(controlled, filter_clicked, filter_type);
    }
  }

  renderFilters(filters, filter_type) {
    let rendered_filters = [];
    if (filters && filters.length > 0) {
      rendered_filters = filters
        .filter(filter => filter.input_type && (filter.name || filter.label))
        .map(filter => (
          <div key={'filter_' + filter.name + filter.label} className="filter-wrapper">
            <input
              key={filter.name}
              className="filter-input"
              name={filter.name}
              type={filter.input_type}
              onChange={() => this.handleFilter(filter, filter_type)}
              checked={filter.selected}
            />
            <label className="filter-label" htmlFor={filter.name}>
              {filter.label}
            </label>
          </div>
        ));
    }
    return rendered_filters;
  }

  /*
   ** Loop through the boxes and create an array of ContentBox components.
   **
   ** param {Array} - boxes
   ** param {String} - column_id
   */
  renderBoxes(boxes) {
    let rendered_boxes = [];
    for (let box of boxes) {
      rendered_boxes.push(
        <ContentBox
          key={box.id + this.state.date}
          style={{ width: '100%' }}
          data={box}
          // collapsed={(column_id === 'dvsidebar' || column_id === 'ls') ? isItMobile() : false} We want to keep these open for now
          dutys_black_list={this.state.dutys_black_list}
          stations_black_list={this.state.stations_black_list}
          onChangeToMDay={this.props.onChangeToMDay}
          refreshPage={this.fetchDayView.bind(this)}
        />,
      );
    }
    return rendered_boxes;
  }

  /*
   ** Return an array of containers. These containers can be used later
   ** for grouping boxes.
   **
   ** param {Array} - containers
   ** param {String} - column_id
   */
  renderContainers(containers) {
    let rendered_containers = [];
    for (let container of containers) {
      let boxes = this.renderBoxes(container.boxes);
      if (boxes && boxes.length > 0) {
        rendered_containers.push(
          <div key={container.id} className="dayview-container">
            <p>{container.label}</p>
            {container.boxes && this.renderBoxes(container.boxes)}
          </div>,
        );
      }
    }
    return rendered_containers;
  }

  /*
   ** Day View is based on a 4 column design. Each style will match the column in the array,
   ** so the order in which they're sent matters.
   ** When the second column has an empty container the width reduced.
   **
   ** param {Array} - columns
   */
  renderColumns(columns) {
    let content = [];
    for (let index = 0; index < columns.length; index++) {
      let style = {};
      if (index === 1 && columns[index].containers.length === 0) {
        //If the 2nd column has no containers then we shrink the space where it goes
        //Preserving at least 60px of empty space.
        style = { width: '100px', minWidth: '60px' };
      }
      content.push(
        <div key={columns[index].id} className={`day-single-column col-${columns[index].id}`} style={style}>
          {columns[index].label && (
            <p>
              <strong>{columns[index].label}</strong>
            </p>
          )}
          {columns[index].containers.length > 0 && this.renderContainers(columns[index].containers)}
        </div>,
      );
    }
    return <div className="columns-wrapper">{content}</div>;
  }

  handleOnClick(data) {
    const { endpoint, endpoint_type, variables } = data;
    if (endpoint_type === 'dayview') {
      this.setState({
        displayRouter: false,
        routerData: null,
      });
      this.fetchDayView(endpoint, variables);
    } else {
      this.setState({
        displayRouter: true,
        routerData: data,
      });
    }
  }

  toggleFilter() {
    this.setState(prevState => ({
      show_filters: !prevState.show_filters,
    }));
  }

  renderTopPanel() {
    if (this.state.filters_hidden) {
      return;
    }

    const { show_filters } = this.state;
    const visibility_class = show_filters ? '' : 'display-none';
    const control_text = show_filters ? 'Hide Filters' : 'Show Filters';
    const icon = show_filters ? 'angle-left' : 'angle-right';
    const max_width = show_filters ? {} : { maxWidth: '125px' };
    return (
      <div className="top-panel" style={max_width}>
        <div className="control" onClick={this.toggleFilter.bind(this)}>
          <span className="control-label">{control_text}</span>
          <FontAwesomeIcon icon={icon} className="control-icon" size="lg" />
        </div>
        <div key="filters" className={classNames('filters', visibility_class)}>
          {this.renderFilters(this.state.duty_filters, filterTypes.DUTY)}
          {this.renderFilters(this.state.station_filters, filterTypes.STATION)}
        </div>
        {this.state.clear_filter_btn && (
          <button
            className={`btn btn-default nd-button ${visibility_class}`}
            style={{
              marginTop: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={this.clearAllFilters.bind(this)}
          >
            <CustomToolTip icon="delete" iconClass="svg-img-white" />
            {this.state.clear_filter_btn_label}
          </button>
        )}
      </div>
    );
  }

  render() {
    const { columns, quickLink } = this.state;
    if (columns.length) {
      return (
        <div key="dayview" className="dayView">
          {quickLink.label ? <Link to={quickLink.routeTo}>{quickLink.label}</Link> : null}
          <APIResponseDialog open={this.state.dialog.open} response={this.state.dialog.response} notify={true} />
          {!this.state.displayRouter ? this.renderTopPanel() : <div />}
          {this.state.displayRouter ? (
            <Router
              key={this.state.routerData.endpoint}
              content={this.state.routerData}
              params={this.state.routerData.variables}
            />
          ) : (
            this.renderColumns(columns)
          )}
        </div>
      );
    } else {
      return <Loading type="bars" color="#e3e3e3" />;
    }
  }
}
