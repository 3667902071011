import React from 'react';
import DayContent from './DayContent';
import Buttonbar from '../Common/Buttonbar';
import DialogBox from '../Common/DialogBox';
import CustomToolTip from '../Common/CustomToolTip';
import { dynamicOEButtonCall } from '../../actions/API';
import APIResponseDialog from '../Common/APIResponseDialog';
import { getColorFromClass } from '../../styles/getColorFromClass';
import classNames from 'classnames';

const buttonTypes = require('../../constants/buttonTypes.js');
const mday_type = {
  RECORDS: 'records',
  DUTY_ROSTER: 'dutyroster',
};

/*
 ** DayPanel handles 2 type of mday_type at the time:
 ** - Records
 ** - Duty Roster
 */
export default class DayPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog_box_data: null,
      showBox: false,
      dialog: {
        open: false,
        response: null,
      },
    };
  }

  /*
   ** Add another case if you need to add another mday_type.
   ** (mday_type) is at the top of this file, so you can add
   ** your own constants if needed.
   ** So far it handles Records and DutyRoster.
   **
   ** @param {Object} data
   */
  getDayContent(data, columnGetFrom) {
    let content;
    switch (this.props.mday_type) {
      case mday_type.RECORDS:
        content = this.getRecords(data);
        break;
      case mday_type.DUTY_ROSTER:
        content = this.getDutyRoster(data);
        break;
      default:
        content = 'NO CONTENT!';
        break;
    }
    return (
      <div key={'no-content'} className="day-content-group">
        {content}
      </div>
    );
  }

  openDialog(response) {
    // just bump it open then immediately close on this end, to leave timing
    // up to the dialog component
    this.setState({ dialog: { open: true, response: response } }, () => {
      setTimeout(() => {
        this?.setState({ dialog: { open: false, response: response } });
      }, 3000);
    });
  }

  handleButton(button) {
    if (button.action === buttonTypes.DIALOG_BOX) {
      this.renderDialogBox(button);
    } else if (button.endpoint && button.method) {
      const variables = button.variables || {};
      dynamicOEButtonCall(`/${button.endpoint}`, button.method, variables).then(response => {
        //refresh the day
        response.status >= 200 && response.status < 300 && this.props.onSuccess();
        this.openDialog(response);
      });
    } else {
      console.warn('endpoint and/or method not found');
    }
  }

  //Close the box and delete the data from the state
  closeBox() {
    this.setState({
      dialog_box_data: null,
      showBox: false,
    });
  }

  renderDialogBox(button) {
    this.setState({
      dialog_box_data: button,
      showBox: true,
    });
  }

  /*
   ** Create the content of the Day for Duty Roster format.
   ** @param {Object} data
   */
  getDutyRoster(data) {
    const containers_key = Object.keys(data).filter(key => Array.isArray(data[key]));
    return containers_key.map(key =>
      data[key].map(content => {
        //This black list is passed from Day View, where the Filters are handled.
        if (this.props.stations_black_list && this.props.stations_black_list.includes(content.id)) {
          return null;
        }
        return (
          <div className="day-content-item" key={content.id + content.label}>
            <DayContent
              dialogInitialPos={this.props.dialogInitialPos}
              dutys_black_list={this.props.dutys_black_list}
              record={content}
              top_buttonbar={data.buttonbar}
              onSuccess={this.props.onSuccess}
              body_label_id={this.props.body_label}
              columnGetFrom={this.props.columnGetFrom}
            />
          </div>
        );
      }),
    );
  }

  /*
   ** Create the content of the Day for Records format.
   ** @param {Object} - data
   */
  getRecords(data) {
    if (data.containers && data.containers.length > 0) {
      return data.containers.map(container => {
        if (this.props.stations_black_list && this.props.stations_black_list.includes(container.id)) {
          return null;
        }
        return (
          <div key={container.id + container.label} className="day-content-item record-wrapper">
            <DayContent
              dialogInitialPos={this.props.dialogInitialPos}
              dutys_black_list={this.props.dutys_black_list}
              record={container}
              top_buttonbar={data.buttonbar}
              onSuccess={this.props.onSuccess}
            />
          </div>
        );
      });
    } else {
      return null;
    }
  }

  handleClick() {
    const { data } = this.props;
    if (data.endpoint_type && data.endpoint) {
      return this.props.onDaySelected(data.endpoint_type, data.endpoint);
    }
  }

  getSideButton() {
    const { left_button } = this.props;
    if (left_button) {
      return (
        <div className="position-relative">
          <div className="side-button">{left_button}</div>
        </div>
      );
    }
  }

  getDialogBox() {
    return (
      <DialogBox
        key="dialog_box"
        data={this.state.dialog_box_data}
        dialog_type={this.state.dialog_box_data.dialog_type}
        onClose={this.closeBox.bind(this)}
        onSuccess={this.props.onSuccess}
        openDialog={this.openDialog.bind(this)}
      />
    );
  }

  renderButtonar(data) {
    if (!data.buttonbar || data.buttonbar.length <= 0) {
      return null;
    }
    return (
      <div className="top-buttonbar">
        <Buttonbar buttonbar={data.buttonbar} handleButton={this.handleButton.bind(this)} />
      </div>
    );
  }

  getPanelHeader(data) {
    let daynum = <span className="day-number">{data.daynum}</span>;

    if (data.daylink) {
      daynum = (
        <span className="day-number dayview-btn-link" onClick={this.handleClick.bind(this)}>
          {data.daynum}
        </span>
      );
    }

    const stickyHeader = {
      paddingBottom: '10px',
      position: 'sticky',
      top: '-10px',
      zIndex: '5',
      backgroundColor: '#fff',
      borderBottom: '2px solid #eee',
    };

    let daynumStyle = {
      paddingTop: '5px',
    };

    if (data && data.dayviewHighlight) {
      daynumStyle = {
        border: `2px solid ${getColorFromClass(data.borderClass)}`,
        borderTop: 'none',
        paddingTop: '5px',
      };
    }

    return (
      <div className="panel-header" style={data.daylink || data.daynum ? stickyHeader : {}}>

        {data.daynum ? <div style={data.daynum ? daynumStyle : {}}>{daynum}</div> : null}

        {data.label || data.details ? (
          <div className="day-header-details">
            <span className={`day-header-label ${data.class}`}>{data.label}</span>
            <span className={data.class} dangerouslySetInnerHTML={{__html: data.details}}></span>
          </div>
        ) : null}

        <div className="alert-wrapper">
          {data.onshift && <CustomToolTip icon="onshift_badge" text={data.onshift_msg} className="alert-badge" />}
          {data.alert && <CustomToolTip icon="alert" text={data.alert_msg} className="alert-icon" />}
        </div>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const { limit_to } = this.props;
    const extraClass = data ? data.today_class : '';
    let borderColor;
    let style = {
      width: `${limit_to}%`,
    };

    if (data && data.dayviewHighlight) {
      borderColor = getColorFromClass(data.borderColor);
      style = Object.assign({}, style, {
        borderTop: `3px solid ${borderColor}`,
      });
    }
    if (data && data.bodyHighlight) {
      borderColor = getColorFromClass(data.borderColor);
      style = Object.assign({}, style, {
        border: `3px solid ${borderColor}`,
      });
    }

    return (
      <div className={classNames('day-panel ', extraClass)} style={style}>
        {this.getSideButton()}
        {data && (
          <div>
            {this.state.dialog_box_data && this.getDialogBox()}
            {this.getPanelHeader(data)}
            {this.renderButtonar(data)}
            <div
              className={'panel-body' + (!data.daylink ? ' dayview-btn-link' : '')}
              onClick={!data.daylink ? this.handleClick.bind(this) : () => {}}>
              {this.getDayContent(data, this.props.columnGetFrom)}
            </div>
            <APIResponseDialog open={this.state.dialog.open} response={this.state.dialog.response} notify={true} />
          </div>
        )}
      </div>
    );
  }
}
